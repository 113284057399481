import React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik, Form, FormikProps, useFormik, useFormikContext } from "formik";
import { BasePageHeader, CenteredColumn, Container, ContainersDiv, ContainerText } from "../utils/styles";
import { LoadingContext } from "../utils/contexts/Loading";
import { Helmet } from "react-helmet";
import { Button, CircularProgress, createStyles, FormControl, FormControlLabel, FormLabel, Link, makeStyles, Radio, RadioGroup, Snackbar, TextField, Typography, Grow } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { ErrorContext } from "../utils/contexts/Error";
import { UserContext } from "../utils/contexts/User";
import { allLocales } from "../utils/locales";
import { useSnackbar } from "notistack";
import {links} from "../utils/constants";
import { MobileContext } from "../utils/contexts/Mobile";

type Props = RouteComponentProps<{id: string}>

const ContributePage = (props: Props) => {
	const {user} = React.useContext(UserContext);
	const {mobile} = React.useContext(MobileContext);
	const [submitted, setSubmitted] = React.useState({error: "", success: false});
	const [isSubmitting, setSubmitting] = React.useState(false);
	const {loading} = React.useContext(LoadingContext);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	if (!user) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{"Contribute | Eventcord Dashboard"}</title>
				</Helmet>
				<Grow in>
					<CenteredColumn>
						<CircularProgress color="secondary" />
						<Typography>
							Fetching user account
						</Typography>
					</CenteredColumn>
				</Grow>
			</React.Fragment>
		);
	}

	if (submitted.success) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{"Contribute | Eventcord Dashboard"}</title>
				</Helmet>
				<CenteredColumn>
					<Grow in>
						<Alert severity="success">Thank you! You will recieve a DM within a few days.</Alert>
					</Grow>
				</CenteredColumn>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>{"Bot Error | Eventcord Dashboard"}</title>
			</Helmet>
			<Formik
				initialValues={{locales: [], email: "", guildCount: null}}
				onSubmit={async ({locales, email, guildCount}) => {
					setSubmitting(true);
					axios.post(process.env.REACT_APP_API + "/contributor-application", {
						locales,
						email,
						guildCount
					}, {
						withCredentials: true
					})
						.then(() => {
							setSubmitting(false);
							setSubmitted({success: true, error: ""});
							enqueueSnackbar("Application submitted!", {variant: "success"});
						})
						.catch(e => {
							setSubmitting(false);
							if (e.response) {
								if (e.response.status === 405) {
									if (e.response.data.notInSupportGuild) {
										enqueueSnackbar("You are not in the support server.", {variant: "error"});
									}
									if (e.response.data.appInProcess) {
										enqueueSnackbar("You already have an application in processing.", {variant: "error"});
									}
									return;
								}
							}
							enqueueSnackbar("Fatal error", {variant: "error"});
						});
				}}
				validate={({locales, email, guildCount}) => {
					const errors = {} as {locales: string, email: string, guildCount: string};
					const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (!email.match(emailRegex) && email.length !== 0) {
						errors.email = "Invalid email";
					}

					if (locales.length > 10) {
						errors.locales = "Too many languages!";
					} else if (locales.length === 0) {
						errors.locales = "Add a language!";
					}

					return errors;
				}}
			>
				{({handleChange, values, errors, setFieldValue}) => (
					<Form>
						<ContainersDiv>
							<Grow in>
								<Container mobile={mobile}>
									<ContainerText>
										<Typography variant="h6">
										Translate Eventcord
										</Typography>
										<Typography variant="body1">
										Complete this form to help translate Eventcord. Please do not select English.
										</Typography>
										<br />
										<Alert severity="info">Make sure you are in the support server. <Link href={links.supportServer} color="secondary" target="_blank">Click here to join</Link></Alert>
										<br />
									</ContainerText>

									<TextField
										required
										variant="filled"
										fullWidth
										label="Email"
										value={values.email}
										name="email"
										onChange={handleChange}
										multiline
										helperText={`${values.email.length}/128${errors.email ? ` (${errors.email})` : ""}`}
										error={Boolean(errors.email)}
									/>

									<br />

									<TextField
										required
										variant="filled"
										fullWidth
										value={values.guildCount}
										onChange={handleChange}
										name="guildCount"
										label="How many servers do you use Eventcord in?"
										type="number"
										inputProps={{
											min: 1,
											max: 10,
											step: 1
										}}
										error={Boolean(errors.guildCount)}
									/>

									<br />
								
									<Autocomplete
										fullWidth
										multiple
										onChange={(event, value) => {
											setFieldValue("locales", value);
										}}
										id="locales"
										options={allLocales.map(v => v.code)}
										value = {values.locales}
										getOptionLabel={(option: any) => allLocales.find(v => v.code === option)!.name}
										renderInput={(params: any) => (
											<TextField
												{...params}
												variant="filled"
												label="Which languages can you translate to?"
												placeholder="Languages"
												helperText={`${values.locales.length}/10`}
												error={Boolean(errors.locales)}
											/>
										)}
									/>

									<Button
										variant="contained"
										color="secondary" 
										type="submit"
										disabled={isSubmitting || Object.keys(errors).length > 0}
									>
								Submit
									</Button>
								</Container>
							</Grow>
						</ContainersDiv>
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};

export {ContributePage};