import { gql, useMutation, useQuery } from "@apollo/client";
import { GuildConfig } from "./types";

const getGuildConfig = (guildId: string) => {
	const query = gql`
		query($guildId: String!) {
			getGuildConfig(guildId: $guildId) {
				locale
				prefix
				roleId
				votekick
				muteEventAdmin
				memberXpEnabled
				xpPerMessage
    			xpPerReaction
    			xpPerReply
				xpPerMinuteVoiceChannel
    			xpPerMinuteMax
				levelUpChannel
				blacklistedRolesXp
				blacklistedVoiceChannelsXp
				levelingRewardRoles {
					role
					level
				}
				levelingRewardRolesStack
			}
		}	
	`;
	return useQuery(query, {variables: {guildId}});
};

const GET_GUILD = gql`
	query($guildId: String!) {
		getGuild(guildId: $guildId) {
			id
			name
			icon
			botPerms
			roles {
				id
				name
				position
			}
			channels {
				id
				type
				name
				position
				parentId
			}
		}
	}
`;

const GET_GUILD_SETUP = gql`
	query($guildId: String!) {
		getGuild(guildId: $guildId) {
			id
			name
			icon
			botPerms
			roles {
				id
				name
				position
			}
			channels {
				id
				type
				name
				position
				parentId
				botPerms
			}
		}
	}
`;

const GET_USER = gql`
	query {
		getUser {
			discordId
			discordTag
			avatar
			guilds {
				id
				name
				icon
			}
		}
	}
`;

const GET_EVENTS = gql`
	query($guildId: String!) {
		getEvents(guildId: $guildId) {
			textChannel
			locked
			autoCycle
			type
			oneRound
			message
			maxGroupSize
		}
	}
`;

const GET_LEADERBOARD = gql`
	query($guildId: String!, $page: Int!) {
		getLeaderboard(guildId: $guildId, page: $page) {
			page
			ranks {
				id
				tag
				avatar
				rank
				xp
				level
				currentLevelXp
				nextLevelXp
			}
		}
	}
`;

const DELETE_MEMBER_ACCOUNT = gql`
		mutation($guildId: String!, $userId: String!) {
			deleteMemberAccount(guildId: $guildId, userId: $userId) {
				success
				userId
			}
		}
	`;

const DELETE_ALL_MEMBER_ACCOUNTS = gql`
mutation($guildId: String!) {
	deleteAllMemberAccounts(guildId: $guildId) {
		success
		guildId
	}
}
`;

const EDIT_EVENT = gql`
		mutation($guildId: String!, $channelId: String!, $input: EventInput!) {
			editEvent(guildId: $guildId, channelId: $channelId, input: $input) {
				textChannel
				locked
				autoCycle
				type
				oneRound
				message
				maxGroupSize
			}
		}
	`;

const EDIT_GUILD_CONFIG = gql`
		mutation($guildId: String!, $input: GuildConfigInput!) {
			editGuildConfig(guildId: $guildId, input: $input) {
				locale
				prefix
				roleId
				votekick
				memberXpEnabled
				muteEventAdmin
				xpPerMessage
    			xpPerReaction
    			xpPerReply
				xpPerMinuteVoiceChannel
    			xpPerMinuteMax
				levelUpChannel
				blacklistedRolesXp
				blacklistedVoiceChannelsXp
				levelingRewardRoles {
					role
					level
				}
				levelingRewardRolesStack
			}
		}
	`;
//const editGuildConfig = useMutation(EDIT_GUILD_CONFIG);

/*const editGuildConfig = (guildId: string, data: GuildConfig) => {
	const query = gql`
		mutation($guildId: String!, $input: GuildConfig!) {
			editGuildConfig(guildId: $guildId, input: $input) {
				locale
				prefix
				role
				votekick
				muteEventAdmin
			}
		}
	`;
	return useMutation(query, {variables: {guildId, input: data}});
};*/

export {getGuildConfig, GET_GUILD, GET_GUILD_SETUP, EDIT_GUILD_CONFIG, GET_USER, GET_EVENTS, EDIT_EVENT, GET_LEADERBOARD, DELETE_MEMBER_ACCOUNT, DELETE_ALL_MEMBER_ACCOUNTS};