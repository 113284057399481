import React from "react";
import {Switch, Route, Redirect, useHistory, useParams} from "react-router-dom";
import { DashboardPage } from "./pages/Dashboard";
import { HomePage } from "./pages/Home";
import {TopNav} from "./components/TopNav";
import * as events from "events";
import { SideMenu } from "./components/SideMenu";
import { Guild, User } from "./utils/types";
import { GuildsContextProvider } from "./utils/contexts/Guilds";
import { GuildContextProvider } from "./utils/contexts/Guild";
import { ApolloClient, createHttpLink, gql, InMemoryCache } from "@apollo/client";
import axios from "axios";
import { NotFound } from "./pages/NotFound";
import { MobileContextProvider } from "./utils/contexts/Mobile";

function App() {
	// Graphql
	/*const apolloClient = new ApolloClient({
		uri: process.env.REACT_APP_API + "/graphql",
		cache: new InMemoryCache(),
		credentials: "include"
	});

	React.useEffect(() => {
		axios.get("http://localhost:3001/api/auth/check", {withCredentials: true}).then(r => console.log(r.data));
		console.log("Fetching guilds");
		const query = gql`
			query {
				getUser {
					discordId
				}	
			}
		`;
		apolloClient.query({ query })
			.then(r => console.log(r)).catch((e) => console.error(e));
		setGuilds(mockGuilds); // Mocks
	}, []);*/

	const [mobile, setMobile] = React.useState<boolean>(window.innerWidth <= 700);

	// Resize
	window.addEventListener("resize", () => {
		if (window.innerWidth <= 700) {
			setMobile(true);
		} else {
			setMobile(false);
		}
	});

	return (
		<React.Fragment>
			<MobileContextProvider value={{mobile, setMobile}}>
				<Switch>
					<Route path="/" exact={true} component={HomePage} />
					<Route 
						path="/dash/" 
						exact={true} 
						component={DashboardPage} 
					/>
					<Route
						path="/dash/:guildId"
						exact={true}
						component={DashboardPage}
					/>
					<Route
						path="/dash/:guildId/config/"
						exact={true}
						component={DashboardPage}
					/>
					<Route
						path="/dash/:guildId/config/:route"
						exact={true}
						component={DashboardPage}
					/>
					
					<Route
						path="/dash/:guildId/manage/events"
						exact={true}
						component={DashboardPage}
					/>
					<Route
						path="/dash/:guildId/manage/events/:channelId"
						exact={true}
						component={DashboardPage}
					/>
					<Route 
						path="/bot-error" 
						exact={true}
						component={DashboardPage} 
					/>
					<Route 
						path="/contribute" 
						exact={true}
						component={DashboardPage} 
					/>
					<Route
						path="/leaderboard/"
						exact={true}
						component={DashboardPage}
					/>
					<Route
						path="/leaderboard/:guildId"
						exact={true}
						component={DashboardPage}
					/>
				
					<Route component={NotFound}/>
				</Switch>
			</MobileContextProvider>
		</React.Fragment>
	);
}

/*function Routes() {
	return (
		<Switch>
			<Route path="/" exact={true} component={HomePage} />
			<Route path="/dash/" exact={true} component={DashboardPage} />
			<Route
				path="/dash/:guildId"
				exact={true}
				component={DashboardPage}
			/>
			<Route
				path="/dash/:guildId/manage/:route"
				exact={true}
				component={DashboardPage}
			/>
		</Switch>
	);
}*/



export default App;
