import { menuGroup } from "./types";

const menuItems = (guildId: string): menuGroup[] => [
	{
		name: "Config",
		path: "/config",
		routes: [
			{
				name: "Bot",
				path: `/dash/${guildId}/config/bot`
			},
			{
				name: "Events",
				path: `/dash/${guildId}/config/events`
			},
			{
				name: "Levels",
				path: `/dash/${guildId}/config/levels`
			},
			{
				name: "Setup",
				path: `/dash/${guildId}/config/setup`
			}
		]
	},
	{
		name: "Manage",
		path: "/manage",
		routes: [
			{
				name: "Events",
				path: `/dash/${guildId}/manage/events`
			}
		]
	}, {
		name: "Leaderboard",
		path: "/leaderboard",
		routes: [
			{
				name: "View Leaderboard",
				path: `/leaderboard/${guildId}`
			}
		]
	}
];

const images = {
	logo: "https://eventcord.xyz/assets/avatar-circle.svg",
	permissionsVideo: "https://i.eventcord.xyz/G0F5p8.webm",
	createRoleVideo: "https://i.eventcord.xyz/rU3X1m.webm"
};

const links = {
	mainSite: "https://eventcord.xyz",
	docs: "https://docs.eventcord.xyz",
	statusPage: "https://status.eventcord.xyz",
	supportServer: "https://go.eventcord.xyz/discord",
	eventAdminRoleInfo: "https://docs.eventcord.xyz/using-the-bot/events#what-are-event-admin-roles",
	invite: "https://eventcord.xyz/invite",
	inviteWizard: "https://go.eventcord.xyz/invite-setup",
	inviteDirect: "https://go.eventcord.xyz/invite-direct"
}; 

export {images, links, menuItems};