import * as React from "react";
import { Guild } from "../types";

type GuildsContextType = {
	guilds?: Guild[];
	setGuilds?: any;
}

const GuildsContext = React.createContext<GuildsContextType>({
	guilds: []
});

const GuildsContextProvider = GuildsContext.Provider;
export {GuildsContext, GuildsContextProvider};