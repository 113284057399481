import * as React from "react";
import { Guild, SelectedGuild } from "../types";

type GuildContextType = {
	guild?: SelectedGuild;
	setGuild?: any;
}

const GuildContext = React.createContext<GuildContextType>({
	guild: undefined
});

const GuildContextProvider = GuildContext.Provider;
export {GuildContext, GuildContextProvider};