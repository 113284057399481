import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Avatar, Menu, MenuItem, IconButton, Typography, Toolbar, AppBar, Button, Link, Icon } from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {Guild, NavProps, User} from "../utils/types";
import * as constants from "../utils/constants";
import {History} from "history";
import { GuildContext } from "../utils/contexts/Guild";
import { GuildsContext } from "../utils/contexts/Guilds";
import EventEmitter from "events";
import { UserContext } from "../utils/contexts/User";
import { generatePath } from "react-router-dom";
import { MobileContext } from "../utils/contexts/Mobile";
import { Slide } from "@material-ui/core";
import { InviteDialogContext } from "../utils/contexts/InviteDialog";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1
		},
		menuButton: {
			marginRight: theme.spacing(2)
		},
		avatar: {
			marginRight: theme.spacing(2)
		},
		serverButton: {
			marginRight: theme.spacing(2)
		},
		title: {
			flexGrow: 1,
		},
		appBar: {
			height: "64px"
		}
	}),
);

function HideOnScroll(props: {window?: () => Window, children: React.ReactElement}) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({ target: window ? window() : undefined });
  
	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

export const TopNav = (props: NavProps): any => {
	const {guild} = React.useContext(GuildContext);
	const {guilds} = React.useContext(GuildsContext);
	const {user} = React.useContext(UserContext);
	const {mobile} = React.useContext(MobileContext);
	const {setInviteDialogOpen} = React.useContext(InviteDialogContext);
	const classes = useStyles();

	// User Menu
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseUser = () => {
		setAnchorElUser(null);
	};
	const handleLogout = () => {
		handleCloseUser();
		window.location.href = process.env.REACT_APP_LOGOUT_URL || "/";
	};

	// Server Menu
	const [anchorElServer, setAnchorElServer] = React.useState<null | HTMLElement>(null);
	const handleClickServer = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElServer(event.currentTarget);
	};
	const handleCloseServer = () => {
		setAnchorElServer(null);
	};

	const menuClick = () => {
		props.setMenuOpen(!props.menuOpen);
	};

	const getGuildIconUrl = (guild?: Guild): string => {
		if (!guild) return "";
		if (guild.icon) {
			return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
		} else {
			return "";
		}
	};

	const getUserAvatarUrl = (): string => {
		if (user) {
			if (user.avatar) {
				if (user.avatar.startsWith("a_")) {
					return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.gif?size=64`;
				}
				return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png?size=64`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${Number(user.discordTag.split("#")[1]) % 5}.png?size=64`;
			}
		} else {
			return "";
		}
	};

	return (
		<div className={classes.root}>
			<HideOnScroll>
				<AppBar>
					<Toolbar>
						<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={menuClick}>
							<MenuIcon />
						</IconButton>
						<Avatar src={constants.images.logo} className={classes.avatar}></Avatar>
						<Typography variant="h6" className={classes.title}>Eventcord Dashboard</Typography>

						{!mobile ? (
							<React.Fragment>
								<Button variant="outlined" color="secondary" className={classes.serverButton} onClick={handleClickServer}>
									<Avatar src={getGuildIconUrl(guild)} className={classes.avatar}></Avatar>
									{guild?.name || "Manage a server"}
								</Button>

								<Menu id="user" anchorEl={anchorElServer} keepMounted open={Boolean(anchorElServer)} onClose={handleCloseServer}>
									{/* Server Selector */}
									{guilds?.map((guild) => (
										<MenuItem key={guild.id} onClick={() => {
											handleCloseServer();
											props.history.push("/dash/" + guild.id + "/");
										//props.history.push(generatePath(props.match.path, {guildId: guild.id, route: props.match.params.route}));
										//window.location.reload();
										}}>
											<Avatar src={getGuildIconUrl(guild)} className={classes.avatar} />
											{guild.name}
										</MenuItem>
									))}
								
									<MenuItem 
										key="invite"
										onClick={() => {
											setInviteDialogOpen(true);
											handleCloseServer();
										}}
									>
										<Avatar className={classes.avatar}>
											<PersonAddIcon />
										</Avatar>
										Invite Bot
									</MenuItem>
								</Menu>

								<Button variant="outlined" color="secondary" onClick={handleClickUser}>
									<Avatar src={getUserAvatarUrl()} className={classes.avatar}></Avatar>
									{user?.discordTag || "User#0001"}
								</Button>

								<Menu id="user" anchorEl={anchorElUser} keepMounted open={Boolean(anchorElUser)} onClose={handleCloseUser}>
									<MenuItem key="logout" onClick={handleLogout}>Logout</MenuItem>
								</Menu>
							</React.Fragment>
						) : null}
					</Toolbar>
				</AppBar>
			</HideOnScroll>
		</div>
	);
};
