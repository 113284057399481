import * as React from "react";

type InviteDialogContextType = {
	inviteDialogOpen?: boolean;
	setInviteDialogOpen?: any;
}

const InviteDialogContext = React.createContext<InviteDialogContextType>({
	inviteDialogOpen: false
});

const InviteDialogProvider = InviteDialogContext.Provider;
export {InviteDialogContext, InviteDialogProvider};