import styled, { css } from "styled-components";

interface Props {
	mobile: boolean
	width?: string
}

interface CenteredColumnProps {
	padding?: string
}

export const BasePageStyle = styled.div<Props>`
display: block;
	min-height: 100vh;
	width: 100%;
	background-color: ${({theme}) => theme.base.content};
	color: ${({theme}) => theme.text.main};
`;

export const BasePageHeader = styled.div`
width: 100%;
text-align: center;
padding: 10px 0;
`;

export const ContainerText = styled.div`
	padding: 5px 0;
	text-align: center;
`;

export const Container = styled.div<Props>`
display: flex;
flex-flow: column wrap;
align-items: center;
justify-content: center;
background-color: ${({theme}) => theme.base.container};
padding: 25px;
margin: 25px;
border-radius: 10px;
width: ${({width}) => {
		return width;
	}};
max-width: ${({mobile}) => {
		if (mobile) return "90%";
		return "50%";
	}};
`;

export const ContainersDiv = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: center;
`;

export const ServerSelectStyle = styled.div`
	display: inline-block;
	justify-content: center;
	white-space: nowrap;
	flex-shrink: 0;
	align-items: center;
	position: fixed;
	text-align: center;
	padding: 25px 15px;
	padding-top: 85px;
	width: 100%;
	position: fixed;
	overflow-y: hidden;
	overflow-x: auto;
	scroll-snap-align: center;
	background-color: ${({theme}) => theme.base.ServerSelect};
	border-width: 0;
	color: ${({theme}) => theme.text.main};
`;

export const ServerSelectItemStyle = styled.div`
	display: inline-block;
	margin: 0 25px;
	background-color: white;
	width: 214px;
	height: 214px;
	padding: 20px;
	border-radius: 25px;
`;

export const HomePageStyle = styled.div`
	font-family: 'Cabin', sans-serif;
	width: 100%;
	min-height: 101vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-bottom: 1vh solid #23272a;
	padding: 40px 5%;
	background-blend-mode: darken;
	background-color: rgba(0, 0, 0, .5);
	background-image: url(${(props: {backgroundImageUrl: string}) => props.backgroundImageUrl});
`;

export const HomePageHeaderStyle = styled.h1`
	font-size: 30px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    width: 100%;
`;

export const HomePageTextStyle = styled.p`
	width: 100%;
    color: #fff;
    font-weight: 400;
    font-size: 32px;
    line-height: 1.5;
    text-align: center;
`;

export const HomePageButtonContainerStyle = styled.div`
	box-sizing: border-box;
    width: 100%;
    color: #fff;
    font-weight: 400;
    font-size: 32px;
    line-height: 1.5;
    text-align: center;
`;

export const HomePageButtonStyle = styled.button`
	width: 100%;
    padding: 17.5px;
    background-color: rgba(0, 0, 0, .5);
    margin: 35px auto 0;
    border-radius: 35px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    border: none;

	&:hover {
		transform: scale(1.125);
	}

	@media screen and (min-width:700px) {
		width: 50%;
	}
`;

export const FooterStype = styled.div`
	font-family: 'Cabin', sans-serif; 
	background-color: #23272a;
    width: 100%;
    overflow: wrap;
    text-align: center;
    color: #fff;
    border-bottom: 1vh solid #23272a;
	line-height: 1.5;
`;

export const CenteredRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const CenteredColumn = styled.div<CenteredColumnProps>`
display: flex;
width: 100%;
flex-flow: column wrap;
align-items: center;
justify-content: center;
padding: ${({padding}) => {
		return padding || "25px";
	}};
`;

export const LeaderboardContainer = styled.div<Props>`
background-color: ${({theme}) => theme.base.container};
padding: 10px;
border-radius: 10px;
width: ${({mobile}) => {
		if (mobile) return "98%";
		return "75%";
	}};
`;

export const LeaderboardItemContainer = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
`;

export const LeaderboardItemContent = styled.div`
display: flex;
align-items: center;
`;

export const LeaderboardItemContentItem = styled.div`
margin: 5px;
`;