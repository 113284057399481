import { CircularProgress, Grow, Typography } from "@material-ui/core";
import { CenteredColumn } from "../utils/styles";
import * as React from "react";
import { LoadingContext } from "../utils/contexts/Loading";

const GuildPage = (props: unknown) => {
	const { loading } = React.useContext(LoadingContext);
	if (loading) {
		return (
			<CenteredColumn>
				<CircularProgress color="secondary" />
				<Typography>
					Fetching server data
				</Typography>
			</CenteredColumn>
		);
	}
	return (
		<Grow in timeout={500}>
			<CenteredColumn>
				<Typography color="error" variant="h6">
					Select a category using the menu on the left.
				</Typography>
			</CenteredColumn>
		</Grow>
	);
};

export { GuildPage };