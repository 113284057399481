import { useQuery, gql, useMutation } from "@apollo/client";
import { DialogContentText, Grid, Avatar, Box, CircularProgress, LinearProgress, createStyles, Divider, makeStyles, Paper, Theme, Typography, Tooltip, Grow, Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SettingsIcon from "@material-ui/icons/Settings";
import { Alert } from "@material-ui/lab";
import React from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";
import { DELETE_MEMBER_ACCOUNT, GET_GUILD, GET_LEADERBOARD, DELETE_ALL_MEMBER_ACCOUNTS } from "../utils/api";
import { LoadingContext } from "../utils/contexts/Loading";
import { UserContext } from "../utils/contexts/User";
import { CenteredColumn, CenteredRow, LeaderboardContainer, LeaderboardItemContainer, LeaderboardItemContent, LeaderboardItemContentItem } from "../utils/styles";
import { DashboardProps, Guild } from "../utils/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { MobileContext } from "../utils/contexts/Mobile";
import classNames from "classnames";
import { GuildContext } from "../utils/contexts/Guild";
import { useSnackbar } from "notistack";

interface LeaderboardMember {
	id: string;
	tag: string;
	avatar: string | null;
	rank: number;
	xp: number;
	level: number;
	currentLevelXp: number;
	nextLevelXp: number;
}

interface States {
	leaderboard: LeaderboardMember[];
	currentPage: number;
	fetchedPage: number;
	guild?: Guild;
	endOfList: boolean;
	expandedAboutAccordion: string;
	manageMemberAnchorEl: null | HTMLElement;
	openManageMemberMenu: string;
	openDialog: string;
}

type LeaderboardProps = RouteComponentProps<{guildId: string}>

const getUserAvatarUrl = (user: LeaderboardMember): string => {
	if (user) {
		if (user.avatar) {
			if (user.avatar.startsWith("a_")) {
				return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.gif?size=64`;
			}
			return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png?size=64`;
		} else {
			return `https://cdn.discordapp.com/embed/avatars/${Number(user.tag.split("#")[1]) % 5}.png?size=64`;
		}
	} else {
		return "";
	}
};

const getGuildIconUrl = (guild?: Guild): string => {
	if (!guild) return "";
	if (guild.icon) {
		return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
	} else {
		return "";
	}
};

const useMaterialStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperNumber: {
			display: "inline-block",
			borderRadius: "20px",
			width: "40px",
			height: "40px",
			verticalAlign: "middle",
			textAlign: "center",
			paddingTop: "8px",
			backgroundColor: "#333333"
		},
		firstPaperColor: {
			backgroundColor: "#dfc951"
		},
		secondPaperColor: {
			backgroundColor: "#EB459E"
		},
		thirdPaperColor: {
			backgroundColor: "#cd7f32"
		},
		inline: {
			display: "inline-block"
		},
		number: {
			fontSize: "15px"
		},
		avatar: {
			height: "64px",
			width: "64px"
		},
		levelProgressTop: {
			position: "absolute",
			left: 0,
			color: "#5865F2"
		}
	}),
);

const LeaderboardPage = (props: LeaderboardProps) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const materialStyles = useMaterialStyles();
	const guildId = props.match.params.guildId;
	let hasManagementPerm = false;
	const managedGuild = React.useContext(GuildContext).guild;
	if (managedGuild) {
		if (managedGuild.id === guildId) {
			hasManagementPerm = true;
		}
	}
	const {mobile} = React.useContext(MobileContext);
	const [state, setState] = React.useState<States>({
		leaderboard: [],
		currentPage: 1,
		fetchedPage: 0,
		guild: undefined,
		endOfList: false,
		expandedAboutAccordion: "",
		manageMemberAnchorEl: null,
		openManageMemberMenu: "",
		openDialog: ""
	});

	// Level progress circle
	function CircularProgressWithLabel(props: {member: LeaderboardMember}) {
		const currentLevelProgressXp = props.member.xp - props.member.currentLevelXp;
		const XpBetweenLevels = props.member.nextLevelXp - props.member.currentLevelXp;
		const percentageComplete = (currentLevelProgressXp / XpBetweenLevels) * 100;
		const XpToGo = XpBetweenLevels - currentLevelProgressXp;
		return (
			<Tooltip title={`Remaining XP to the next level (${convertNumberToString(currentLevelProgressXp)}/${convertNumberToString(XpBetweenLevels)} XP)`}>
				<Box position="relative" display="inline-flex">
					<CircularProgress
						variant="determinate"
						size={60}
						value={100}
					/>
					<CircularProgress
						className={materialStyles.levelProgressTop}
						variant="determinate" 
						value={percentageComplete}
						size={60}
					/>
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Typography variant="caption" component="div" color="textSecondary">{`${convertNumberToString(XpToGo)} XP`}</Typography>
					</Box>
				</Box>
			</Tooltip>
		);
	}

	// handle scrolling
	const nextPage = () => {
		setState({
			...state,
			currentPage: state.currentPage + 1
		});
	};

	// invalid guild return
	const invalidGuild = (
		<React.Fragment>
			<Helmet>
				<title>{"Eventcord Leaderboard"}</title>
			</Helmet>
			<CenteredColumn>
				<Alert severity="error">Invalid server</Alert>
			</CenteredColumn>
		</React.Fragment>
	);

	const GET_GUILD_BASIC = gql`
		query($guildId: String!) {
			getGuild(guildId: $guildId) {
				id
				name
				icon
			}
		}
	`;
	const guildQueryReturn = useQuery(GET_GUILD_BASIC, {variables: {guildId}, skip: Boolean(state.guild)});
	React.useEffect(() => {
		const data = guildQueryReturn.data;
		if (data && !state.guild) {
			setState({
				...state,
				guild: data.getGuild
			});
		}
	}, [guildQueryReturn]);


	// reset all XP mutation
	const [deleteAllMemberAccountsMutation] = useMutation(DELETE_ALL_MEMBER_ACCOUNTS, {
		onError: (e) => {
			console.error(e);
			enqueueSnackbar("Error deleting all member accounts", {variant: "error"});
		},
		onCompleted: (data) => {
			const mutationData = data.deleteAllMemberAccounts;
			enqueueSnackbar(`Deleted ${mutationData.success || 0} member accounts`, {variant: "success", persist: false});
			setState({
				...state,
				leaderboard: [],
				endOfList: true
			});
		}
	});

	// delete member account mutation
	const [deleteMemberAccountMutation] = useMutation(DELETE_MEMBER_ACCOUNT, {
		onError: (e) => {
			console.error(e);
			enqueueSnackbar("Error resetting member account", {variant: "error"});
		},
		onCompleted: (data) => {
			const mutationData = data.deleteMemberAccount;
			if (mutationData.success === 1) {
				const member = state.leaderboard.find((m) => m.id === mutationData.userId);
				let username = "unknown user";
				if (member) username = member.tag;
				enqueueSnackbar(`Deleted ${username}`, {variant: "success", persist: false});
				
				// remove visual of member
				let memberFound = false;
				for (let i = 0; i < state.leaderboard.length && !memberFound; i++) {
					if (state.leaderboard[i].id === mutationData.userId) {
						memberFound = true;
						state.leaderboard.splice(i, 1);
					}
				}

				setState({
					...state,
					leaderboard: state.leaderboard
				});
			} else {
				enqueueSnackbar("Could not delete member; member not found", {variant: "error", persist: false});
			}
		}
	});


	const leaderboardQueryReturn = useQuery(GET_LEADERBOARD, {variables: {guildId, page: state.currentPage}, skip: state.fetchedPage === state.currentPage});
	React.useEffect(() => {
		const data = leaderboardQueryReturn.data;
		if (data && data.getLeaderboard.page !== state.fetchedPage) {
			const ranks = data.getLeaderboard.ranks;
			if (ranks.length === 0) {
				setState({
					...state,
					fetchedPage: data.getLeaderboard.page,
					endOfList: true
				});
				return;
			}
			setState({
				...state,
				fetchedPage: data.getLeaderboard.page,
				leaderboard: state.leaderboard.concat(data.getLeaderboard.ranks)
			});
		}
	}, [leaderboardQueryReturn]);

	if (guildQueryReturn.loading) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{"Eventcord Leaderboard"}</title>
				</Helmet>
				<CenteredColumn>
					<CircularProgress color="secondary" />
					<Typography>
						Fetching server
					</Typography>
				</CenteredColumn>
			</React.Fragment>
		);
	}

	if (!state.guild || !guildId) {
		return invalidGuild;
	}

	if (guildQueryReturn.error) {
		const error = guildQueryReturn.error;
		console.error(error);
		return invalidGuild;
	}

	if (leaderboardQueryReturn.error) {
		const error = leaderboardQueryReturn.error;
		console.error(error);
		return invalidGuild;
	}

	const getNumberClass = (rank: number) => {
		switch (rank) {
		case 1: 
			return classNames(materialStyles.paperNumber, materialStyles.firstPaperColor);
		case 2:
			return classNames(materialStyles.paperNumber, materialStyles.secondPaperColor);
		case 3:
			return classNames(materialStyles.paperNumber, materialStyles.thirdPaperColor);
		default: 
			return materialStyles.paperNumber;
		}
	};

	const AboutDialog = () => {
		const handleChange = (panel: string) => (event: unknown, isExpanded: boolean) => {
			setState({
				...state,
				expandedAboutAccordion: isExpanded ? panel : ""
			});
		};

		return (
			<Dialog 
				open={state.openDialog === "aboutLeveling"} 
				onClose={() => {setState({
					...state,
					openDialog: ""
				});}}
			>
				<DialogTitle>About Leveling</DialogTitle>

				<Accordion 
					expanded={state.expandedAboutAccordion === "panel1"}
					onChange={handleChange("panel1")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<Typography>
						What is the number on the left with the circle around it?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							This number is the amount of XP left for the respective member to reach the next level. The circle around the number visualizes this progress as a percentage.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion 
					expanded={state.expandedAboutAccordion === "panel2"}
					onChange={handleChange("panel2")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<Typography>
						How do I earn XP?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							You can earn XP by sending messages consistently, reciving reaction, reciving replies, and spending time in voice channels. Your server administrators can customize the amount of XP earned for each form of engagement.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion 
					expanded={state.expandedAboutAccordion === "panel3"}
					onChange={handleChange("panel3")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<Typography>
						Can I customize my profile card?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Unfortunately, profile cards cannot currently be customized. Some users may have customized rank cards as the feature is in very limited testing.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion 
					expanded={state.expandedAboutAccordion === "panel4"}
					onChange={handleChange("panel4")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<Typography>
						I'm a server administrator. How can I configure leveling?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
						If you have the <strong>Manage Server</strong> permission, you can configure this server's leveling by selecting "leveling" under the config section in the menu on the left.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion 
					expanded={state.expandedAboutAccordion === "panel5"}
					onChange={handleChange("panel5")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<Typography>
						Can the leaderboard page be customized?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
						No. The leaderboard page cannot currently be customized. This may be a feature in the future.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion 
					expanded={state.expandedAboutAccordion === "panel6"}
					onChange={handleChange("panel6")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<Typography>
						Is the leaderboard page public?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
						Currently, leaderboards can only be viewed by members of the respective server. This is why visiting Eventcord leaderboards requires members to authenticate themselves with Discord.
						</Typography>
					</AccordionDetails>
				</Accordion>

				<DialogActions>
					<Button
						variant="contained" 
						color="primary"
						startIcon={<CloseIcon />}
						onClick={() => {setState({
							...state,
							openDialog: ""
						});}}
					>
					Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	// management perms for each member
	const managementOptions = (member: LeaderboardMember) => {
		if (!hasManagementPerm) return null;

		const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setState({
				...state,
				manageMemberAnchorEl: event.currentTarget,
				openManageMemberMenu: member.id
			});
		};

		const handleClose = (dialogToOpen?: any) => {
			setState({
				...state,
				manageMemberAnchorEl: null,
				openManageMemberMenu: "",
				openDialog: typeof dialogToOpen === "string" ? `manage-${member.id}-${dialogToOpen}` : ""
			});
		};

		return (
			<React.Fragment>
				<Tooltip title="Manage member">
					<IconButton onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
				</Tooltip>
				<Menu
					anchorEl={state.manageMemberAnchorEl}
					keepMounted
					open={state.openManageMemberMenu === member.id}
					onClose={handleClose}
				>
					<MenuItem onClick={() => {handleClose("reset");}}><DeleteForeverIcon style={{color: "#ED4245"}}/> Reset member XP</MenuItem>
				</Menu>

				<Dialog 
					open={state.openDialog === `manage-${member.id}-reset`} 
					onClose={handleClose}
				>
					<DialogTitle>Reset {member.tag}</DialogTitle>
					<DialogContent>
						<Typography>
						This will reset their XP and leveling progress. Previously rewarded roles must be removed manually.
						</Typography>
						<Typography color="error">
							This is not reversible.
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							startIcon={<CheckIcon style={{color: "#57F287"}} />}
							onClick={() => {
								deleteMemberAccountMutation({variables: {guildId, userId: member.id}});
								enqueueSnackbar(`Deleting member account for ${member.tag}`, {variant: "info", persist: false});
								handleClose();
							}}
						>
						Confirm
						</Button>
						<Button
							variant="contained" 
							color="primary"
							startIcon={<CloseIcon style={{color: "#ED4245"}} />}
							onClick={handleClose}
						>
						Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	};

	const overallManagementOptions = () => {
		if (!hasManagementPerm) return null;
		const handleClose = () => {
			setState({
				...state,
				openDialog: ""
			});
		};
		return (
			<Grid item>
				<Button 
					variant="contained" 
					color="primary"
					startIcon={<SettingsIcon />}
					onClick={() => {setState({
						...state,
						openDialog: "overallManagement"
					});}}
				>
				Manage Leaderboard
				</Button>

				{/* Reset all XP dialog */}
				<Dialog
					open={state.openDialog === "overallManagement-reset"}
					onClose={handleClose}
				>
					<DialogTitle>Reset all XP</DialogTitle>
					<DialogContent>
						<Typography>
							This will reset all member XP.
						</Typography>
						<Typography color="error">
							This is not reversible.
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							startIcon={<CheckIcon style={{color: "#57F287"}} />}
							onClick={() => {
								handleClose();
								deleteAllMemberAccountsMutation({variables: {guildId}});
								enqueueSnackbar("Deleting all member accounts", {variant: "info", persist: false});
							}}
						>
						Confirm
						</Button>
						<Button
							variant="contained" 
							color="primary"
							startIcon={<CloseIcon style={{color: "#ED4245"}} />}
							onClick={handleClose}
						>
						Cancel
						</Button>
					</DialogActions>
				</Dialog>

				{/* Management dialog */}
				<Dialog 
					open={state.openDialog === "overallManagement"} 
					onClose={handleClose}
				>
					<DialogTitle>Manage Leaderboard</DialogTitle>
					<DialogContent>
						<Button
							variant="contained" 
							color="primary"
							startIcon={<DeleteForeverIcon style={{color: "#ED4245"}}/>}
							onClick={() => {
								setState({
									...state,
									openDialog: "overallManagement-reset"
								});
							}}
						>
							Reset all XP
						</Button>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained" 
							color="primary"
							startIcon={<CloseIcon />}
							onClick={handleClose}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		);
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${state.guild.name} | Eventcord Leaderboard`}</title>
			</Helmet>
			<CenteredColumn>
				<Avatar className={materialStyles.avatar} src={getGuildIconUrl(state.guild)} />
				<Typography align="center" variant="h4">
					{state.guild.name} Leaderboard
				</Typography>
			</CenteredColumn>
			<Grid container spacing={2} justify="center">
				<Grid item justify="center">
					<Button 
						variant="contained" 
						color="primary"
						startIcon={<InfoIcon />}
						onClick={() => {setState({
							...state,
							openDialog: "aboutLeveling"
						});}}
					>
					About Leveling
					</Button>
					{AboutDialog()}
				</Grid>

				{/* Overall management options */}
				{overallManagementOptions()}
			</Grid>

			{/* Leaderboard container below */}
			<CenteredColumn>
				<LeaderboardContainer mobile={mobile}>
					<InfiniteScroll
						dataLength={state.leaderboard.length}
						next={nextPage}
						hasMore={!state.endOfList}
						endMessage={
							<CenteredColumn>
								<Typography color="textSecondary">
									🌵 You've reached the end of the leaderboard!
								</Typography>
							</CenteredColumn>
						}
						loader={ <LinearProgress color="secondary" /> }
					>
						{state.leaderboard.map((member, i) => (
							<Grow in>
								<div>
									<LeaderboardItemContainer>
										<LeaderboardItemContent>
											<LeaderboardItemContentItem>
												<Paper className={getNumberClass(member.rank)}>
													<Typography>
														{member.rank}
													</Typography>
												</Paper>
											</LeaderboardItemContentItem>
											<LeaderboardItemContentItem>
												<Avatar className={materialStyles.avatar} src={getUserAvatarUrl(member)} />
											</LeaderboardItemContentItem>
											<LeaderboardItemContentItem>
												<Typography variant="h6">
													{member.tag}
												</Typography>
											</LeaderboardItemContentItem>
										</LeaderboardItemContent>

										<LeaderboardItemContent>
											<LeaderboardItemContentItem>
												{/* Management options */}
												{managementOptions(member)}
											</LeaderboardItemContentItem>
											<LeaderboardItemContentItem>
												<Typography>
													{convertNumberToString(member.xp)} XP | Level {member.level}
												</Typography>
											</LeaderboardItemContentItem>
											<LeaderboardItemContentItem>
												<CircularProgressWithLabel member={member} />
											</LeaderboardItemContentItem>
										</LeaderboardItemContent>
									</LeaderboardItemContainer>
									<Divider />
								</div>
							</Grow>
						))}
					</InfiniteScroll>
				</LeaderboardContainer>
			</CenteredColumn>
		</React.Fragment>
	);
};

function convertNumberToString(number: number, maxDecimal = 1) {
	return Math.abs(number) > 999 ? Math.sign(number)*(Number((Math.abs(number)/1000).toFixed(maxDecimal))) + "k" : Math.sign(number)*Math.abs(number);
}

export {LeaderboardPage};