import * as React from "react";
import { Button, CircularProgress, createStyles, Fab, Fade, FormControlLabel, FormHelperText, Hidden, IconButton, makeStyles, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Theme, Tooltip, Typography, Zoom } from "@material-ui/core";
import { BasePageHeader, BasePageStyle, ContainerText, Container, ContainersDiv, CenteredColumn } from "../utils/styles";
import axios from "axios";
import { Alert, AlertTitle } from "@material-ui/lab";
import { GuildContext } from "../utils/contexts/Guild";
import { GuildsContext } from "../utils/contexts/Guilds";
import { Route } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import { gql, useMutation, useQuery } from "@apollo/client";
import { LoadingContext } from "../utils/contexts/Loading";
import { ErrorContext } from "../utils/contexts/Error";
import { Formik, Form, FormikProps, useFormik, useFormikContext } from "formik";
import { GuildConfig } from "../utils/types";
import { getGuildConfig, EDIT_GUILD_CONFIG } from "../utils/api";
import EventEmitter from "events";
import { Helmet } from "react-helmet";
import { MobileContext } from "../utils/contexts/Mobile";

const afterSubmitTimeout = 1000;

// Fab styles
const useMaterialStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			width: 500,
			position: "relative",
			minHeight: 200,
		},
		fabSave: {
			position: "fixed",
			bottom: theme.spacing(4),
			right: theme.spacing(4),
		},
		fabReset: {
			position: "fixed",
			bottom: theme.spacing(14),
			right: theme.spacing(5),
		},
		hidden: {
			visibility: "hidden"
		}
		/*fabGreen: {
			color: theme.palette.common.white,
			backgroundColor: green[500],
			"&:hover": {
				backgroundColor: green[600],
			},
		},*/
	}),
);

const ConfigBotPage = (props: any) => {
	const materialClasses = useMaterialStyles();
	const { guildId } = props.match.params;
	const { guild, setGuild } = React.useContext(GuildContext);
	const { loading, setLoading } = React.useContext(LoadingContext);
	const { error, setError } = React.useContext(ErrorContext);
	const { guilds } = React.useContext(GuildsContext);
	const { mobile } = React.useContext(MobileContext);
	const [guildConfig, setGuildConfig] = React.useState<GuildConfig>();
	const [fetchedGuildConfig, setFetchedGuildConfig] = React.useState<boolean>(false);
	const [formModified, setFormModified] = React.useState<boolean>(false);

	const [editConfig, editConfigReturn] = useMutation(EDIT_GUILD_CONFIG);

	const configDataQuery = getGuildConfig(guildId);

	const rolesSorted: { name: string, position: number, id: string }[] = [];
	guild?.roles?.forEach(role => {
		if (role.name === "@everyone") return;
		rolesSorted.push({
			name: role.name,
			position: role.position,
			id: role.id
		});
	});
	rolesSorted.sort((a, b) => {
		if (a.position > b.position) return -1;
		if (a.position < b.position) return 1;
		return 0;
	});

	React.useEffect(() => {
		if (guild) {
			if (!guildConfig && !fetchedGuildConfig) {
				console.log("Fetching guild config data");
				const { loading, error, data } = configDataQuery;
				if (loading) {
					setLoading(true);
					return;
				}
				if (error) {
					console.error(error);
					setError("Cannot fetch config data for " + guild.name);
					return;
				}
				if (data) {
					console.log("config fetched");
					setFetchedGuildConfig(true);
					setLoading(false);
					if (data.getGuildConfig !== null) {
						setGuildConfig(data.getGuildConfig);
						return;
					}
					setError("No guild account. Please use the bot on your server.");
					return;
				}
			}
		}
	});

	React.useEffect(() => {
		// Edit guild config
		const { loading, error, data } = editConfigReturn;
		if (loading) {
			setLoading(true);
			return;
		}
		if (error) {
			setLoading(false);
			console.error(editConfigReturn.error);
			setError("Cannot update data");
			return;
		}
		if (data) {
			setLoading(false);
			setGuildConfig(data.editGuildConfig);
			console.log("updated guild config");
		}
	}, [editConfigReturn]);

	if (guildConfig) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{`Config - ${guild?.name} | Eventcord Dashboard`}</title>
				</Helmet>
				<Fade in timeout={500}>
					<div>
						<BasePageHeader>
							<Typography variant="h4">
								Configure Bot
							</Typography>
						</BasePageHeader>
						<Formik
							enableReinitialize
							initialValues={{
								prefix: guildConfig.prefix
							}}
							onReset={() => {
								setFormModified(false);
							}}
							onSubmit={({ prefix }, { setSubmitting }) => {
								setFormModified(false);
								editConfig({ variables: { input: { prefix }, guildId } });
								setTimeout(() => {
									setSubmitting(false);
								}, afterSubmitTimeout);
							}}
							validate={(values) => {
								const errors = {} as { prefix: string, unchanged: boolean };

								// dev
								let changed = false;
								Object.keys(values).forEach(key => {
									//@ts-ignore
									if (values[key] !== guildConfig[key]) {
										changed = true;
									}
								});
								if (changed) {
									setFormModified(true);
								} else {
									setFormModified(false);
									errors.unchanged = true;
								}

								// prefix check
								const prefix = values.prefix || "";
								if (prefix.length > 5) {
									errors.prefix = "Too long! (> 5 characters)";
								} else if (prefix.length === 0) {
									errors.prefix = "Enter a value";
								} else if (prefix.includes("`")) {
									errors.prefix = "Invalid character";
								}

								return errors;
							}}
						>
							{({ errors, handleChange, initialValues, isSubmitting, resetForm, setValues, handleReset, values }) => (
								<Form>
									<ContainersDiv>
										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
													Prefix
												</Typography>
												<Typography variant="body1">
													Here you can change your guild's prefix.
												</Typography>
											</ContainerText>
											<Alert severity="info">
												Eventcord now only supports slash commands. To use a slash command, type "/".
												Prefixes no longer work as Discord no longer allows bots to read messages without permission for privacy.
											</Alert>
											{/*
											Disabled since prefixes are no longer usable
											<TextField variant="filled" 
												error={Boolean(errors.prefix)} 
												value={values.prefix} 
												helperText={`${values.prefix!.length}/5${errors.prefix ? ` (${errors.prefix})`: ""}`}
												label="Prefix"
												name="prefix"
							onChange={handleChange}/>*/}
										</Container>
									</ContainersDiv>
									<Fade in={formModified && !isSubmitting}>
										<div>
											<Tooltip title="Save">
												<Fab
													className={materialClasses.fabSave}
													color="secondary"
													type="submit"
													disabled={Object.keys(errors).length > 0}
													onChange={handleChange}>
													<SaveIcon />
												</Fab>
											</Tooltip>
											<Tooltip title="Revert">
												<Fab size="small" className={materialClasses.fabReset} color="secondary" aria-label="reset" type="reset" onClick={() => resetForm()}>
													<UndoIcon />
												</Fab>
											</Tooltip>
										</div>
									</Fade>
								</Form>
							)}
						</Formik>
					</div>
				</Fade>
			</React.Fragment>
		);
	} else {
		return (
			<CenteredColumn>
				<CircularProgress color="secondary" />
				<Typography>
					Fetching server account
				</Typography>
			</CenteredColumn>
		);
	}
};

export { ConfigBotPage };