import * as constants from "../utils/constants";
import * as React from "react";
import { Avatar, makeStyles, Link } from "@material-ui/core";
import { HomePageButtonStyle, HomePageButtonContainerStyle, HomePageHeaderStyle, HomePageStyle, HomePageTextStyle, FooterStype } from "../utils/styles";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
	text: {
		color: "#fff"
	}
});

const NotFound = (props: any) => {
	const classes = useStyles();
	const loginUrl = process.env.REACT_APP_LOGIN_URL;
	return (
		<React.Fragment>
			<Helmet>
				<title>404 Not Found | Eventcord Dashboard</title>
			</Helmet>
			<HomePageStyle backgroundImageUrl={`${process.env.PUBLIC_URL}/images/homeBackground.png`}>
				<HomePageHeaderStyle>Eventcord Dashboard</HomePageHeaderStyle>
				<HomePageTextStyle>404 Not Found</HomePageTextStyle>
				<HomePageButtonContainerStyle>
					<a href="/"><HomePageButtonStyle>Return to the dashboard</HomePageButtonStyle></a>
				</HomePageButtonContainerStyle>
			</HomePageStyle>
			<FooterStype>
				<Link href={constants.links.mainSite} className={classes.text}>
					Eventcord
				</Link>
			</FooterStype>
		</React.Fragment>
	);
};

export {NotFound};