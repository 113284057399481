import * as React from "react";

type LoadingContextType = {
	loading?: boolean;
	setLoading?: any;
}

const LoadingContext = React.createContext<LoadingContextType>({});

const LoadingContextProvider = LoadingContext.Provider;
export {LoadingContext, LoadingContextProvider};