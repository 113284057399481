import * as React from "react";

type ErrorContextType = {
	error?: string | null;
	setError?: any;
}

const ErrorContext = React.createContext<ErrorContextType>({
	error: null
});

const ErrorContextProvider = ErrorContext.Provider;
export {ErrorContext, ErrorContextProvider};