import * as React from "react";
import { Divider, Avatar, Button, CircularProgress, Fade, Grow, LinearProgress, makeStyles, Snackbar, Typography, Toolbar, Dialog, DialogTitle, DialogContent, DialogActions, Link, List, ListItem, ListItemText, ListItemAvatar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { BasePageHeader, BasePageStyle, CenteredColumn, LeaderboardContainer, LeaderboardItemContainer, LeaderboardItemContent, LeaderboardItemContentItem } from "../utils/styles";
import axios from "axios";
import { GuildContextProvider } from "../utils/contexts/Guild";
import { GuildsContext, GuildsContextProvider } from "../utils/contexts/Guilds";
import {LoadingContextProvider} from "../utils/contexts/Loading";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigBotPage } from "./DashboardConfigBot";
import { ConfigEventsPage } from "./DashboardConfigEvents";
import { ConfigLevelsPage } from "./DashboardConfigLevels";
import { TopNav } from "../components/TopNav";
import { DashboardProps, Guild, SelectedGuild, User } from "../utils/types";
import { SideMenu } from "../components/SideMenu";
import events from "events";
import { ApolloClient, ApolloProvider, gql, InMemoryCache, useQuery } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { UserContextProvider } from "../utils/contexts/User";
import { ErrorContextProvider } from "../utils/contexts/Error";
import { ConfigSetupPage } from "./DashboardConfigSetup";
import {GET_GUILD, GET_USER} from "../utils/api";
import { ManageEventsPage } from "./DashboardManageEvents";
import { Helmet } from "react-helmet";
import { GuildPage } from "./GuildPage";
import { MobileContext } from "../utils/contexts/Mobile";
import { BotErrorPage } from "./BotError";
import { LeaderboardPage } from "./Leaderboard";
import { ContributePage } from "./Contribute";
import { InviteDialogProvider } from "../utils/contexts/InviteDialog";
import { links } from "../utils/constants";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import LaunchIcon from "@material-ui/icons/Launch";

const useMaterialStyles = makeStyles({
	loadingBar: {
		position: "fixed",
		width: "100%"
	},
	alertBar: {
		position: "fixed",
		bottom: 0
	}
});

const DashboardPage = (props: DashboardProps) => {
	const materialClasses = useMaterialStyles();
	const eventEmitter = new events.EventEmitter();
	const history = props.history;
	const {guildId} = props.match.params;
	// States
	const {mobile} = React.useContext(MobileContext);
	const [guild, setGuild] = React.useState<SelectedGuild | undefined>();
	const [guilds, setGuilds] = React.useState<Guild[]>([]);
	const [user, setUser] = React.useState<User>();
	const [authorized, setAuthorized] = React.useState<boolean>(true);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [selectedGuildDataFetching, setSelectedGuildDataFetching] = React.useState<string>();
	const [error, setError] = React.useState<string | undefined>(undefined);
	const [inviteDialogOpen, setInviteDialogOpen] = React.useState(false);
	const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

	/*const apolloLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.forEach(({ message, locations, path }) =>
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
				),
			);
		if (networkError) console.log(`[Network error]: ${networkError}`);
		setError("API Error");
	});*/

	const apolloClient = new ApolloClient({
		uri: process.env.REACT_APP_API + "/graphql",
		cache: new InMemoryCache(),
		credentials: "include",
		//link: apolloLink
	});
	
	React.useEffect(() => {
		setLoading(true);
		axios.get(process.env.REACT_APP_API + "/auth/check", {withCredentials: true})
			.then(r => {
				if (r.data == false) {
					const params = () => {
						const locationParams = window.location.href.split("?")[1];
						if (locationParams) {
							return "&" + locationParams;
						}
						return "";
					};
					const uri = encodeURI(process.env.REACT_APP_LOGIN_URL + `?dash_redirect_path=${props.location.pathname}` + params());
					window.location.href = uri;
					setAuthorized(false);
					return;
				}

				console.log("Fetching guild");
				apolloClient.query({ query: GET_USER })
					.then(r => {
						if (r.data) {
							setGuilds(r.data.getUser.guilds);
							setUser(r.data.getUser);
							setLoading(false);
						} else {
							setError("Cannot access data");
						}
					})
					.catch((e) => {
						console.error(e);
						setError("Cannot access API");
					});
			}).catch((e) => {
				console.error(e);
				setError("Cannot access API");
			});
	}, []);

	/*React.useEffect(() => {
		const find = guilds?.find(g => g.id === guildId);
		setGuild(find);
	});*/

	/*const skipGuildDataQuery = () => {
		if (!foundGuild) {
			return true;
		}
		if (selectedGuildDataFetching === foundGuild.id) {
			return true; 
		}
		return false;
	};

	const foundGuild = guilds?.find(g => g.id === guildId);
	const guildDataQuery = useQuery(GET_GUILD, {
		variables: {guildId: guildId},
		skip: skipGuildDataQuery()
	});

	if (guildDataQuery.loading) {
		setSelectedGuildDataFetching(foundGuild!.id);
	}
	if (guildDataQuery.error) {
		console.error(guildDataQuery.error);
		setError("Cannot get guild data");
	}
	if (guildDataQuery.data) {
		const newData = guildDataQuery.data.getGuild;
		if (newData) {
			setGuild(newData);
			setLoading(false);
		} else {
			setError("Cannot fetch guild data");
		}
	}*/
	
	React.useEffect(() => {
		const find = guilds?.find(g => g.id === guildId);

		if (find) if (selectedGuildDataFetching !== find.id) {
			setSelectedGuildDataFetching(find.id);
			console.log(`fetching added guild data for ${find.name}/${find.id}`);
			setLoading(true);
			apolloClient.query({query: GET_GUILD, variables: {guildId: guildId}})
				.then(r => {
					console.log(r);
					if (r.data) {
						const newData = r.data.getGuild;
						if (newData) {
							setGuild(newData);
							setLoading(false);
						} else {
							setError("Cannot fetch guild data");
						}
					}
				})
				.catch((e) => {
					console.error(e);
					setError("Cannot access API");
				});
		}
	});

	// Page content
	const getGuildIconUrl = (guild?: Guild): string => {
		if (!guild) return "";
		if (guild.icon) {
			return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
		} else {
			return "";
		}
	};
	const getUserAvatarUrl = (): string => {
		if (user) {
			if (user.avatar) {
				if (user.avatar.startsWith("a_")) {
					return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.gif?size=64`;
				}
				return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png?size=64`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${Number(user.discordTag.split("#")[1]) % 5}.png?size=64`;
			}
		} else {
			return "";
		}
	};
	const getGreeting = (): string => {
		const hour = new Date().getHours();
		if (hour < 12) {
			return "Good morning";
		} else if (hour < 18) {
			return "Good afternoon";
		} else {
			return "Good evening";
		}
	};

	const pageContent = (
		<Switch>
			{/* Non-management routes */}
			<Route
				path="/bot-error"
				exact={true}
				component={BotErrorPage}
			/>
			<Route
				path="/contribute"
				exact={true}
				component={ContributePage}
			/>

			<Route
				path="/leaderboard/"
				exact={true}
				component={LeaderboardPage}
			/>
			<Route
				path="/leaderboard/:guildId"
				exact={true}
				component={LeaderboardPage}
			/>

			{/* Management routes */}
			{guild ? (
				<React.Fragment>
					<Helmet>
						<title>{`${guild?.name} | Eventcord Dashboard`}</title>
					</Helmet>
					<BasePageHeader>
						<Grow timeout={500} in>
							<Typography variant="h3">
								{guild!.name}
							</Typography>
						</Grow>
					</BasePageHeader>
					<Route
						path="/dash/:guildId"
						exact={true}
						component={GuildPage}
					/>

					<Route
						path="/dash/:guildId/config/bot"
						exact={true}
						component={ConfigBotPage}
					/>

					<Route
						path="/dash/:guildId/config/events"
						exact={true}
						component={ConfigEventsPage}
					/>

					<Route
						path="/dash/:guildId/config/levels"
						exact={true}
						component={ConfigLevelsPage}
					/>

					<Route
						path="/dash/:guildId/config/setup"
						exact={true}
						component={ConfigSetupPage}
					/>

					<Route
						path="/dash/:guildId/manage/events"
						exact={true}
						component={ManageEventsPage}
					/>
				
					<Route
						path="/dash/:guildId/manage/events/:channelId"
						exact={true}
						component={ManageEventsPage}
					/>
				</React.Fragment>
			) : null}

			{/* Not found management route */}
			<Route>
				<React.Fragment>
					{guilds.length !== 0 && user ? 
						<BasePageHeader>
							{guildId ? 
								<React.Fragment>
									<CircularProgress color="secondary" />
									<Typography>
										Fetching server data
									</Typography>
								</React.Fragment>
								: 
								<Grow in>
									<CenteredColumn>
										<Avatar src={getUserAvatarUrl()} style={{width: 64, height: 64}} />
										<Typography align="center" variant="h5">
											{getGreeting()} {user.discordTag.split("#")[0]}, please select a server.
										</Typography>
										
										<LeaderboardContainer mobile={mobile}>
											{/* Server Selector */}
											{guilds.map((guild, i) => (
												<Grow in timeout={1e3}>
													<div>
														<LeaderboardItemContainer key={guild.id}>
															<LeaderboardItemContent>
																<LeaderboardItemContentItem>
																	<Avatar src={getGuildIconUrl(guild)} />
																</LeaderboardItemContentItem>
																<LeaderboardItemContentItem>
																	<Typography variant="h6">
																		{guild.name}
																	</Typography>
																</LeaderboardItemContentItem>
															</LeaderboardItemContent>
															<LeaderboardItemContent>
																<LeaderboardItemContentItem>
																	<Button
																		variant="contained" 
																		color="primary"
																		onClick={() => {
																			props.history.push("/leaderboard/" + guild.id + "/");
																		}}
																	>
																	Leaderboard
																	</Button>
																</LeaderboardItemContentItem>
																<LeaderboardItemContentItem>
																	<Button
																		variant="contained" 
																		color="primary"
																		startIcon={<SettingsIcon />}
																		onClick={() => {
																			props.history.push("/dash/" + guild.id + "/");
																		}}
																	>
																	Dashboard
																	</Button>
																</LeaderboardItemContentItem>
															</LeaderboardItemContent>
														</LeaderboardItemContainer>
														{i !== guilds.length - 1 ? (<Divider />) : ""}
													</div>
												</Grow>
											))}
										</LeaderboardContainer>
									</CenteredColumn>
								</Grow>
							}
						</BasePageHeader>
						:
						<CenteredColumn>
							<CircularProgress color="secondary" />
							<Typography>
								Fetching user
							</Typography>
						</CenteredColumn>}
				</React.Fragment>
			</Route>
		</Switch>
	);

	let loadingBar;
	if (loading) {
		loadingBar = (
			<React.Fragment>
				<LinearProgress color="secondary" className={materialClasses.loadingBar} />
			</React.Fragment>
		);
	}

	let alertBar;
	if (error !== undefined) {
		alertBar = (
			<React.Fragment>
				
			</React.Fragment>
		);
	}

	// Invite dialog
	const inviteDialog = () => {
		if (!inviteDialogOpen) {
			return "";
		}
		const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
			setInviteDialogOpen(false);
		};

		return (		
			<Dialog 
				open={inviteDialogOpen} 
				onClose={handleClose}
			>
				<DialogTitle>Invite Eventcord</DialogTitle>
				<DialogContent>
					<Typography>
						Invite Eventcord to your server!
					</Typography>
					<Alert severity="info">You must have the Manage Server permission</Alert>
					<CenteredColumn>
						<Link href={links.inviteWizard} target="_blank">
							<Button
								variant="contained"
								color="primary"
								onClick={handleClose}
							>
								Invite with setup wizard <LaunchIcon />
							</Button>
						</Link>
						<br />
						<Link href={links.inviteDirect} target="_blank">
							<Button
								variant="contained"
								color="primary"
								onClick={handleClose}
							>
							Just invite and setup later <LaunchIcon />
							</Button>
						</Link>
					</CenteredColumn>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained" 
						color="primary"
						startIcon={<CloseIcon />}
						onClick={handleClose}
					>
					Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<React.Fragment>
			<ApolloProvider client={apolloClient}>
				<InviteDialogProvider value={{inviteDialogOpen, setInviteDialogOpen}}>
					<ErrorContextProvider value={{error, setError}}>
						<LoadingContextProvider value={{loading, setLoading}}>
							<UserContextProvider value={{user, setUser}}>
								<GuildContextProvider value={{guild, setGuild}}>
									<GuildsContextProvider value={{guilds, setGuilds}}>
										{loadingBar}
										{alertBar}
										{inviteDialog()}
										<Snackbar open={Boolean(error)}>
											<Alert severity="error" onClose={() => setError(undefined)}>{error}</Alert>
										</Snackbar>
										<TopNav setMenuOpen={setMenuOpen} menuOpen={menuOpen} history={history} match={props.match} />
										<SideMenu setMenuOpen={setMenuOpen} menuOpen={menuOpen} history={history} match={props.match} />
										<BasePageStyle mobile={mobile}>
											<Toolbar />
											{pageContent}
										</BasePageStyle>
									</GuildsContextProvider>
								</GuildContextProvider>
							</UserContextProvider>
						</LoadingContextProvider>
					</ErrorContextProvider>
				</InviteDialogProvider>
			</ApolloProvider>
		</React.Fragment>
	);
};

export {DashboardPage};