import * as React from "react";
import { Button, Card, CardActions, CardContent, Chip, CircularProgress, createStyles, Divider, Fab, Fade, FormControlLabel, FormHelperText, Hidden, IconButton, InputLabel, ListSubheader, makeStyles, MenuItem, Paper, Radio, RadioGroup, Select, Slider, Switch, TextField, Theme, Tooltip, Typography, Zoom } from "@material-ui/core";
import { BasePageHeader, BasePageStyle, ContainerText, Container, ContainersDiv, CenteredColumn, CenteredRow } from "../utils/styles";
import axios from "axios";
import { GuildContext } from "../utils/contexts/Guild";
import { GuildsContext } from "../utils/contexts/Guilds";
import { Route } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import AddIcon from "@material-ui/icons/Add";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { gql, useMutation, useQuery } from "@apollo/client";
import { LoadingContext } from "../utils/contexts/Loading";
import { ErrorContext } from "../utils/contexts/Error";
import { Formik, Form, FormikProps, useFormik, useFormikContext } from "formik";
import { GuildConfig } from "../utils/types";
import { getGuildConfig, EDIT_GUILD_CONFIG } from "../utils/api";
import EventEmitter from "events";
import { Helmet } from "react-helmet";
import { MobileContext } from "../utils/contexts/Mobile";
import classNames from "classnames";

const maxRewardRoles = 50;

const afterSubmitTimeout = 1000;

// Fab styles
const useMaterialStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			width: 500,
			position: "relative",
			minHeight: 200,
		},
		fabSave: {
			position: "fixed",
			bottom: theme.spacing(4),
			right: theme.spacing(4),
		},
		fabReset: {
			position: "fixed",
			bottom: theme.spacing(14),
			right: theme.spacing(5),
		},
		hidden: {
			visibility: "hidden"
		},
		select: {
			maxWidth: "100%"
		},
		chip: {
			margin: "2px"
		},
		multipleContainerInputs: {
			margin: "5px"
		},
		category: {
			//fontStyle: "italic",
			textDecoration: "underline",
			textAlign: "center",
			fontWeight: "bold",
			//margin: "5px 0",
			//opacity: "1 !important",
		}
		/*fabGreen: {
			color: theme.palette.common.white,
			backgroundColor: green[500],
			"&:hover": {
				backgroundColor: green[600],
			},
		},*/
	}),
);

const ConfigLevelsPage = (props: any) => {
	const materialClasses = useMaterialStyles();
	const { guildId } = props.match.params;
	const { guild, setGuild } = React.useContext(GuildContext);
	const { loading, setLoading } = React.useContext(LoadingContext);
	const { error, setError } = React.useContext(ErrorContext);
	const { guilds } = React.useContext(GuildsContext);
	const { mobile } = React.useContext(MobileContext);
	const [guildConfig, setGuildConfig] = React.useState<GuildConfig>();
	const [fetchedGuildConfig, setFetchedGuildConfig] = React.useState<boolean>(false);
	const [formModified, setFormModified] = React.useState<boolean>(false);
	const [addRewardRoleId, setAddRewardRoleId] = React.useState<string>("");
	const [addRewardRoleLevel, setAddRewardRoleLevel] = React.useState<number | "">("");

	const [editConfig, editConfigReturn] = useMutation(EDIT_GUILD_CONFIG);

	const configDataQuery = getGuildConfig(guildId);

	const rolesSorted: { name: string, position: number, id: string }[] = [];
	guild?.roles?.forEach(role => {
		if (role.name === "@everyone") return;
		rolesSorted.push({
			name: role.name,
			position: role.position,
			id: role.id
		});
	});
	rolesSorted.sort((a, b) => b.position - a.position);

	const channelsSorted: { name: string, type: number, id: string }[] = [];

	guild!.channels!.concat()
		.sort((a, b) => a.position - b.position)
		.filter(v => v.type === 4)
		.forEach(channel => {
			channelsSorted.push({
				name: channel.name,
				type: channel.type,
				id: channel.id
			});
		});

	guild!.channels!.concat()
		.sort((a, b) => b.position - a.position)
		.filter(v => (v.type !== 4))
		//.filter(v => (v.type === 0))
		.forEach(channel => {
			const channelData = {
				name: channel.name,
				type: channel.type,
				id: channel.id
			};
			if (!channel.parentId) {
				return channelsSorted.splice(0, 0, channelData);
			}
			const foundIndex = channelsSorted.findIndex(v => v.id === channel.parentId);
			if (foundIndex === undefined) return;
			return channelsSorted.splice(foundIndex + 1, 0, channelData);
		});

	React.useEffect(() => {
		if (guild) {
			if (!guildConfig && !fetchedGuildConfig) {
				console.log("Fetching guild config data");
				const { loading, error, data } = configDataQuery;
				if (loading) {
					setLoading(true);
					return;
				}
				if (error) {
					console.error(error);
					setError("Cannot fetch config data for " + guild.name);
					return;
				}
				if (data) {
					console.log("config fetched");
					setFetchedGuildConfig(true);
					setLoading(false);
					if (data.getGuildConfig !== null) {
						setGuildConfig(data.getGuildConfig);
						return;
					}
					setError("No guild account. Please use the bot on your server.");
					return;
				}
			}
		}
	});

	React.useEffect(() => {
		// Edit guild config
		const { loading, error, data } = editConfigReturn;
		if (loading) {
			setLoading(true);
			return;
		}
		if (error) {
			setLoading(false);
			console.error(editConfigReturn.error);
			setError("Cannot update data");
			return;
		}
		if (data) {
			setLoading(false);
			setGuildConfig(data.editGuildConfig);
			console.log("updated guild config");
		}
	}, [editConfigReturn]);

	if (guildConfig) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{`Levels - ${guild?.name} | Eventcord Dashboard`}</title>
				</Helmet>
				<Fade in timeout={500}>
					<div>
						<BasePageHeader>
							<Typography variant="h4">
								Configure Leveling
							</Typography>
						</BasePageHeader>
						<Formik
							enableReinitialize
							initialValues={{
								memberXpEnabled: guildConfig.memberXpEnabled,
								xpPerMessage: guildConfig.xpPerMessage,
								xpPerReaction: guildConfig.xpPerReaction,
								xpPerReply: guildConfig.xpPerReply,
								xpPerMinuteVoiceChannel: guildConfig.xpPerMinuteVoiceChannel,
								xpPerMinuteMax: guildConfig.xpPerMinuteMax,
								levelUpChannel: guildConfig.levelUpChannel,
								blacklistedRolesXp: guildConfig.blacklistedRolesXp,
								blacklistedVoiceChannelsXp: guildConfig.blacklistedVoiceChannelsXp,
								levelingRewardRoles: guildConfig.levelingRewardRoles,
								levelingRewardRolesStack: guildConfig.levelingRewardRolesStack
							}}
							onReset={() => {
								setFormModified(false);
							}}
							onSubmit={({ memberXpEnabled, xpPerMessage, xpPerReaction, xpPerReply, xpPerMinuteVoiceChannel, xpPerMinuteMax, levelUpChannel, blacklistedRolesXp, blacklistedVoiceChannelsXp, levelingRewardRoles, levelingRewardRolesStack }, { setSubmitting }) => {
								setFormModified(false);
								const levelingRewardRolesMapped = levelingRewardRoles!.map(v => ({ role: v.role, level: v.level }));
								editConfig({ variables: { input: { memberXpEnabled, xpPerMessage, xpPerReaction, xpPerReply, xpPerMinuteVoiceChannel, xpPerMinuteMax, levelUpChannel, blacklistedRolesXp, blacklistedVoiceChannelsXp, levelingRewardRoles: levelingRewardRolesMapped, levelingRewardRolesStack }, guildId } });
								setTimeout(() => {
									setSubmitting(false);
								}, afterSubmitTimeout);
							}}
							validate={(values) => {
								const errors = {} as { xpPerMessage: string, xpPerReaction: string, xpPerReply: string, xpPerMinuteVoiceChannel: string, xpPerMinuteMax: string, levelUpChannel: string, levelingRewardRoles: string, unchanged: boolean };

								let changed = false;
								Object.keys(values).forEach(key => {
									//@ts-ignore
									if (values[key] !== guildConfig[key]) {
										changed = true;
									}
								});
								if (changed) {
									setFormModified(true);
								} else {
									setFormModified(false);
									errors.unchanged = true;
								}

								// xpPerMessage check
								let xpPerMessage = values.xpPerMessage;
								if (xpPerMessage !== null) {
									xpPerMessage = Number(xpPerMessage);
									if (xpPerMessage < 1 || xpPerMessage > 50) {
										errors.xpPerMessage = "Invalid value (min 1, max 50)";
									}
								}

								// xpPerReaction check
								let xpPerReaction = values.xpPerReaction;
								if (xpPerReaction !== null) {
									xpPerReaction = Number(xpPerReaction);
									if (xpPerReaction < 1 || xpPerReaction > 50) {
										errors.xpPerReaction = "Invalid value (min 1, max 50)";
									}
								}

								// xpPerReply check
								let xpPerReply = values.xpPerReply;
								if (xpPerReply !== null) {
									xpPerReply = Number(xpPerReply);
									if (xpPerReply < 1 || xpPerReply > 50) {
										errors.xpPerReply = "Invalid value (min 1, max 50)";
									}
								}

								// xpPerMinuteVoiceChannel check
								let xpPerMinuteVoiceChannel = values.xpPerMinuteVoiceChannel;
								if (xpPerMinuteVoiceChannel !== null) {
									xpPerMinuteVoiceChannel = Number(xpPerMinuteVoiceChannel);
									if (xpPerMinuteVoiceChannel < 1 || xpPerMinuteVoiceChannel > 50) {
										errors.xpPerMinuteVoiceChannel = "Invalid value (min 1, max 50)";
									}
								}

								// xpPerMinuteMax check
								let xpPerMinuteMax = values.xpPerMinuteMax;
								if (xpPerMinuteMax !== null) {
									xpPerMinuteMax = Number(xpPerMinuteMax);
									if (xpPerMinuteMax < 1 || xpPerMinuteMax > 150) {
										errors.xpPerMinuteMax = "Invalid value (min 1, max 150)";
									}
								}

								// level up channel check
								const levelUpChannel = values.levelUpChannel;
								if (levelUpChannel === "subheader") {
									errors.levelUpChannel = "Invalid channel";
								}

								// reward roles check
								const levelingRewardRoles = values.levelingRewardRoles || [];
								if (levelingRewardRoles.length > maxRewardRoles) {
									errors.levelingRewardRoles = "Too many roles";
								}
								return errors;
							}}
						>
							{({ errors, handleChange, initialValues, isSubmitting, resetForm, setValues, handleReset, setFieldValue, setFieldError, values }) => (
								<Form>
									<ContainersDiv>
										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
													Enable Leveling Module
												</Typography>
												<Typography variant="body1">
													Enable the leveling module.
												</Typography>
											</ContainerText>
											<Switch
												checked={values.memberXpEnabled || false}
												onChange={handleChange}
												name="memberXpEnabled"
											/>
											<FormHelperText>{errors.memberXpEnabled || null}</FormHelperText>
										</Container>
										<Fade in={values.memberXpEnabled}>
											<ContainersDiv>
												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															XP Per Message
														</Typography>
														<Typography variant="body1">
															How much XP to give a member for each message sent each minute by a member.
														</Typography>
													</ContainerText>
													<Switch
														checked={values.xpPerMessage === null ? false : true}
														onChange={(event) => {
															const value = event.target.checked;
															if (value === true) {
																setFieldValue("xpPerMessage", 15);
															} else {
																setFieldValue("xpPerMessage", null);
															}
														}}
														name="xpPerMessageSwitch"
													/>
													<Slider
														value={values.xpPerMessage || 15}
														valueLabelDisplay="auto"
														step={1}
														min={1}
														max={50}
														marks
														name="xpPerMessage"
														onChange={(event, value) => {
															setFieldValue("xpPerMessage", value);
														}}
														disabled={values.xpPerMessage === null ? true : false}
													/>
													<TextField
														value={values.xpPerMessage || 15}
														onChange={handleChange}
														disabled={values.xpPerMessage === null ? true : false}
														name="xpPerMessage"
														label="XP"
														type="number"
														inputProps={{
															min: 1,
															max: 50,
															step: 1
														}}
														error={Boolean(errors.xpPerMessage)}
														helperText={errors.xpPerMessage || null}
													/>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															XP Per Reaction
														</Typography>
														<Typography variant="body1">
															How much XP to give a member for each reaction they recieve.
														</Typography>
													</ContainerText>
													<Switch
														checked={values.xpPerReaction === null ? false : true}
														onChange={(event) => {
															const value = event.target.checked;
															if (value === true) {
																setFieldValue("xpPerReaction", 5);
															} else {
																setFieldValue("xpPerReaction", null);
															}
														}}
														name="xpPerReactionSwitch"
													/>
													<Slider
														value={values.xpPerReaction || 5}
														valueLabelDisplay="auto"
														step={1}
														min={1}
														max={50}
														marks
														name="xpPerReaction"
														onChange={(event, value) => {
															setFieldValue("xpPerReaction", value);
														}}
														disabled={values.xpPerReaction === null ? true : false}
													/>
													<TextField
														value={values.xpPerReaction || 5}
														onChange={handleChange}
														disabled={values.xpPerReaction === null ? true : false}
														name="xpPerReaction"
														label="XP"
														type="number"
														inputProps={{
															min: 1,
															max: 50,
															step: 1
														}}
														error={Boolean(errors.xpPerReaction)}
														helperText={errors.xpPerReaction || null}
													/>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															XP Per Reply
														</Typography>
														<Typography variant="body1">
															How much XP to give a member for each reply they recieve.
														</Typography>
													</ContainerText>
													<Switch
														checked={values.xpPerReply === null ? false : true}
														onChange={(event) => {
															const value = event.target.checked;
															if (value === true) {
																setFieldValue("xpPerReply", 15);
															} else {
																setFieldValue("xpPerReply", null);
															}
														}}
														name="xpPerReplySwitch"
													/>
													<Slider
														value={values.xpPerReply || 15}
														valueLabelDisplay="auto"
														step={1}
														min={1}
														max={50}
														marks
														name="xpPerReply"
														onChange={(event, value) => {
															setFieldValue("xpPerReply", value);
														}}
														disabled={values.xpPerReply === null ? true : false}
													/>
													<TextField
														value={values.xpPerReply || 15}
														onChange={handleChange}
														disabled={values.xpPerReply === null ? true : false}
														name="xpPerReply"
														label="XP"
														type="number"
														inputProps={{
															min: 1,
															max: 50,
															step: 1
														}}
														error={Boolean(errors.xpPerReply)}
														helperText={errors.xpPerReply || null}
													/>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															XP Per Minute in a Voice Channel
														</Typography>
														<Typography variant="body1">
															How much XP to give a member for each each minute they spend in a voice channel
														</Typography>
													</ContainerText>
													<Switch
														checked={values.xpPerMinuteVoiceChannel === null ? false : true}
														onChange={(event) => {
															const value = event.target.checked;
															if (value === true) {
																setFieldValue("xpPerMinuteVoiceChannel", 10);
															} else {
																setFieldValue("xpPerMinuteVoiceChannel", null);
															}
														}}
														name="xpPerMinuteVoiceChannelSwitch"
													/>
													<Slider
														value={values.xpPerMinuteVoiceChannel || 15}
														valueLabelDisplay="auto"
														step={1}
														min={1}
														max={50}
														marks
														name="xpPerMinuteVoiceChannel"
														onChange={(event, value) => {
															setFieldValue("xpPerMinuteVoiceChannel", value);
														}}
														disabled={values.xpPerMinuteVoiceChannel === null ? true : false}
													/>
													<TextField
														value={values.xpPerMinuteVoiceChannel || 15}
														onChange={handleChange}
														disabled={values.xpPerMinuteVoiceChannel === null ? true : false}
														name="xpPerMinuteVoiceChannel"
														label="XP"
														type="number"
														inputProps={{
															min: 1,
															max: 50,
															step: 1
														}}
														error={Boolean(errors.xpPerMinuteVoiceChannel)}
														helperText={errors.xpPerMinuteVoiceChannel || null}
													/>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															Maximum XP Per Minute
														</Typography>
														<Typography variant="body1">
															The maximum amount of XP a member can recieve in a minute (to prevent spam).
														</Typography>
													</ContainerText>
													{/*<Switch 
														checked={values.xpPerMinuteMax === null ? false : true}
														onChange={(event) => {
															const value = event.target.checked;
															if (value === true) {
																setFieldValue("xpPerMinuteMax", 50);
															} else {
																setFieldValue("xpPerMinuteMax", null);
															}
														}}
														name="xpPerMinuteMaxSwitch"
													/>*/}
													<Slider
														value={values.xpPerMinuteMax || 50}
														valueLabelDisplay="auto"
														step={1}
														min={1}
														max={150}
														marks
														name="xpPerMinuteMax"
														onChange={(event, value) => {
															setFieldValue("xpPerMinuteMax", value);
														}}
														disabled={values.xpPerMinuteMax === null ? true : false}
													/>
													<TextField
														value={values.xpPerMinuteMax || 50}
														onChange={handleChange}
														disabled={values.xpPerMinuteMax === null ? true : false}
														name="xpPerMinuteMax"
														label="XP"
														type="number"
														inputProps={{
															min: 1,
															max: 150,
															step: 1
														}}
														error={Boolean(errors.xpPerMinuteMax)}
														helperText={errors.xpPerMinuteMax || null}
													/>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															Blacklisted Roles
														</Typography>
														<Typography variant="body1">
															Disable roles from receiving XP
														</Typography>
													</ContainerText>
													<Select
														className={materialClasses.select}
														onChange={handleChange}
														value={values.blacklistedRolesXp}
														name="blacklistedRolesXp"
														error={Boolean(errors.blacklistedRolesXp)}
														label="Blacklisted Roles"
														variant="outlined"
														multiple
													>
														{rolesSorted.map((role) => (
															<MenuItem value={role.id}>{role.name}</MenuItem>
														))}
													</Select>
													<FormHelperText>Add a Role</FormHelperText>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															Blacklisted Voice Channels
														</Typography>
														<Typography variant="body1">
															Disable voice channels from rewarding XP
														</Typography>
													</ContainerText>
													<Select
														className={materialClasses.select}
														onChange={handleChange}
														value={values.blacklistedVoiceChannelsXp}
														name="blacklistedVoiceChannelsXp"
														error={Boolean(errors.blacklistedVoiceChannelsXp)}
														label="Blacklisted Voice Channels"
														variant="outlined"
														multiple
													>
														{channelsSorted.filter(c => [2, 4].includes(c.type)).map((channel) => channel.type === 4 ?
															(
																<MenuItem disabled className={materialClasses.category}>{channel.name.toUpperCase()}</MenuItem>
															) : (
																<MenuItem value={channel.id}>{`🔊 ${channel.name}`}</MenuItem>
															))}
													</Select>
													<FormHelperText>Add a Voice Channel</FormHelperText>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															Stack Rewards
														</Typography>
														<Typography variant="body1">
															Whether to stack rewards or remove old rewards.
														</Typography>
													</ContainerText>
													<Switch
														checked={values.levelingRewardRolesStack || false}
														onChange={handleChange}
														name="levelingRewardRolesStack"
													/>
													<FormHelperText>{errors.levelingRewardRolesStack || null}</FormHelperText>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															Level Up Notifications
														</Typography>
														<Typography variant="body1">
															Choose where level up notifications are sent.
														</Typography>
													</ContainerText>
													<Select
														className={materialClasses.select}
														onChange={handleChange}
														value={values.levelUpChannel}
														name="levelUpChannel"
														error={Boolean(errors.levelUpChannel)}
														label="Level Up Channel"
														variant="outlined"
													>
														<MenuItem value="dm">DMs</MenuItem>
														<Divider />
														{channelsSorted.filter(c => [0, 4].includes(c.type)).map((channel) => channel.type === 4 ?
															(
																<MenuItem disabled className={materialClasses.category}>{channel.name.toUpperCase()}</MenuItem>
															) : (
																<MenuItem value={channel.id}>#{channel.name}</MenuItem>
															))}
													</Select>
													{errors.levelUpChannel ? <InputLabel>{errors.levelUpChannel}</InputLabel> : ""}
													<FormHelperText>Choose a channel or DMs</FormHelperText>
												</Container>

												<Container mobile={mobile}>
													<ContainerText>
														<Typography variant="h6">
															Role Rewards
														</Typography>
														<Typography variant="body1">
															Add roles to apply at certain levels. Levels are assigned by the bot based on xp.
														</Typography>
													</ContainerText>

													<Select
														className={classNames(materialClasses.select, materialClasses.multipleContainerInputs)}
														value={addRewardRoleId}
														onChange={(event) => {
															setAddRewardRoleId(String(event.target.value));
														}}
														label="Role"
														variant="outlined"
													>
														{rolesSorted.map((role) => (
															<MenuItem value={role.id}>{role.name}</MenuItem>
														))}
													</Select>

													<TextField
														className={materialClasses.multipleContainerInputs}
														value={addRewardRoleLevel}
														onChange={(event) => {
															setAddRewardRoleLevel(Number(event.target.value));
														}}
														name="createLevelRewardRoleLevel"
														label="Level"
														type="number"
														inputProps={{
															min: 0,
															max: 500,
															step: 1
														}}
													/>
													<Tooltip title="Add">
														<Fab
															className={materialClasses.multipleContainerInputs}
															size="small"
															color="primary"
															disabled={addRewardRoleId === null || addRewardRoleLevel === null || (values.levelingRewardRoles || []).length >= maxRewardRoles}
															onClick={() => {
																const roleId = addRewardRoleId;
																const level = addRewardRoleLevel;
																if (roleId !== "" && level !== "") {
																	setAddRewardRoleId("");
																	const array = values.levelingRewardRoles!.concat([{ role: roleId, level }]);
																	array.sort((a, b) => Number(a.level) - Number(b.level));
																	setFieldValue("levelingRewardRoles", array);
																}
															}}
														>
															<AddIcon />
														</Fab>
													</Tooltip>
													<FormHelperText className={materialClasses.multipleContainerInputs}>{(values.levelingRewardRoles || []).length >= maxRewardRoles ? "Max reward roles" : ""}</FormHelperText>
													<Card>
														<CardContent>
															<Typography>
																{(values.levelingRewardRoles || []).length === 0 ? "No roles" : ""}
															</Typography>
															{values.levelingRewardRoles?.map((reward, i) => (
																<Chip
																	className={materialClasses.chip}
																	label={`Level ${reward.level} | ${(rolesSorted.find(r => r.id === reward.role) || { name: "deleted role" }).name}`}
																	onDelete={() => {
																		const array = (values.levelingRewardRoles || []).concat();
																		array.splice(i, 1);
																		setFieldValue("levelingRewardRoles", array);
																	}}
																/>
															))}
														</CardContent>
													</Card>
												</Container>
											</ContainersDiv>
										</Fade>
									</ContainersDiv>
									<Fade in={formModified && !isSubmitting}>
										<div>
											<Tooltip title="Save">
												<Fab
													className={materialClasses.fabSave}
													color="secondary"
													type="submit"
													disabled={Object.keys(errors).length > 0}
													onChange={handleChange}>
													<SaveIcon />
												</Fab>
											</Tooltip>
											<Tooltip title="Revert">
												<Fab size="small" className={materialClasses.fabReset} color="secondary" aria-label="reset" type="reset" onClick={() => resetForm()}>
													<UndoIcon />
												</Fab>
											</Tooltip>
										</div>
									</Fade>
								</Form>
							)}
						</Formik>
					</div>
				</Fade>
			</React.Fragment>
		);
	} else {
		return (
			<CenteredColumn>
				<CircularProgress color="secondary" />
				<Typography>
					Fetching server account
				</Typography>
			</CenteredColumn>
		);
	}
};

export { ConfigLevelsPage };