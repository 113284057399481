import * as React from "react";
import { Button, CircularProgress, createStyles, Fab, Fade, FormControlLabel, FormHelperText, Hidden, IconButton, makeStyles, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Theme, Tooltip, Typography, Zoom } from "@material-ui/core";
import { BasePageHeader, BasePageStyle, ContainerText, Container, ContainersDiv, CenteredColumn } from "../utils/styles";
import axios from "axios";
import { GuildContext } from "../utils/contexts/Guild";
import { GuildsContext } from "../utils/contexts/Guilds";
import { Route } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import { gql, useMutation, useQuery } from "@apollo/client";
import { LoadingContext } from "../utils/contexts/Loading";
import { ErrorContext } from "../utils/contexts/Error";
import { Formik, Form, FormikProps, useFormik, useFormikContext } from "formik";
import { GuildConfig } from "../utils/types";
import { getGuildConfig, EDIT_GUILD_CONFIG } from "../utils/api";
import EventEmitter from "events";
import { Helmet } from "react-helmet";
import { MobileContext } from "../utils/contexts/Mobile";

const afterSubmitTimeout = 1000;

// Fab styles
const useMaterialStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			width: 500,
			position: "relative",
			minHeight: 200,
		},
		fabSave: {
			position: "fixed",
			bottom: theme.spacing(4),
			right: theme.spacing(4),
		},
		fabReset: {
			position: "fixed",
			bottom: theme.spacing(14),
			right: theme.spacing(5),
		},
		hidden: {
			visibility: "hidden"
		}
		/*fabGreen: {
			color: theme.palette.common.white,
			backgroundColor: green[500],
			"&:hover": {
				backgroundColor: green[600],
			},
		},*/
	}),
);

const ConfigEventsPage = (props: any) => {
	const materialClasses = useMaterialStyles();
	const {guildId} = props.match.params;
	const {guild, setGuild} = React.useContext(GuildContext);
	const {loading, setLoading} = React.useContext(LoadingContext);
	const {error, setError} = React.useContext(ErrorContext);
	const {guilds} = React.useContext(GuildsContext);
	const {mobile} = React.useContext(MobileContext);
	const [guildConfig, setGuildConfig] = React.useState<GuildConfig>();
	const [fetchedGuildConfig, setFetchedGuildConfig] = React.useState<boolean>(false);
	const [formModified, setFormModified] = React.useState<boolean>(false);

	const [editConfig, editConfigReturn] = useMutation(EDIT_GUILD_CONFIG);

	const configDataQuery = getGuildConfig(guildId);

	const rolesSorted: {name: string, position: number, id: string}[] = [];
	guild?.roles?.forEach(role => {
		if (role.name === "@everyone") return;
		rolesSorted.push({
			name: role.name,
			position: role.position,
			id: role.id
		});
	});
	rolesSorted.sort((a, b) => {
		if (a.position > b.position) return -1;
		if (a.position < b.position) return 1;
		return 0;
	});

	React.useEffect(() => {
		if (guild) {
			if (!guildConfig && !fetchedGuildConfig) {
				console.log("Fetching guild config data");
				const {loading, error, data} = configDataQuery;
				if (loading) {
					setLoading(true);
					return;
				}
				if (error) {
					console.error(error);
					setError("Cannot fetch config data for " + guild.name);
					return;
				}
				if (data) {
					console.log("config fetched");
					setFetchedGuildConfig(true);
					setLoading(false);
					if (data.getGuildConfig !== null) {
						setGuildConfig(data.getGuildConfig);
						return;
					}
					setError("No guild account. Please use the bot on your server.");
					return;
				}
			}
		}
	});

	React.useEffect(() => {
		// Edit guild config
		const {loading, error, data} = editConfigReturn;
		if (loading) {
			setLoading(true);
			return;
		}
		if (error) {
			setLoading(false);
			console.error(editConfigReturn.error);
			setError("Cannot update data");
			return;
		}
		if (data) {
			setLoading(false);
			setGuildConfig(data.editGuildConfig);
			console.log("updated guild config");
		}
	}, [editConfigReturn]);
	if (guildConfig) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{`Config - ${guild?.name} | Eventcord Dashboard`}</title>
				</Helmet>
				<Fade in timeout={500}>
					<div>
						<BasePageHeader>
							<Typography variant="h4">
								Configure Events
							</Typography>
						</BasePageHeader>
						<Formik
							enableReinitialize
							initialValues={{
								roleId: guildConfig.roleId,
								votekick: guildConfig.votekick,
								muteEventAdmin: guildConfig.muteEventAdmin
							}}
							onReset={() => {
								setFormModified(false);
							}}
							onSubmit={({roleId, muteEventAdmin, votekick}, {setSubmitting}) => {
								setFormModified(false);
								editConfig({variables: {input: {roleId, muteEventAdmin, votekick}, guildId}});
								setTimeout(() => {
									setSubmitting(false);
								}, afterSubmitTimeout);
							}}
							validate={(values) => {
								const errors = {} as {roleId: string, unchanged: boolean};

								// dev
								let changed = false;
								Object.keys(values).forEach(key => {
									//@ts-ignore
									if (values[key] !== guildConfig[key]) {
										changed = true;
									}
								});
								if (changed) {
									setFormModified(true);
								} else {
									setFormModified(false);
									errors.unchanged = true;
								}

								// roles check
								const role = rolesSorted.find((r) => r.id === values.roleId);
								if (role) {
									if (role.name === "@everyone") {
										errors.roleId = "Cannot choose @everyone";
									}
								} else {
									errors.roleId = "Role does not exist. If you haven't created the default role and want to use it, please create the role.";
								}
								return errors;
							}}
						>
							{({errors, handleChange, initialValues, isSubmitting, resetForm, setValues, handleReset, values}) => (
								<Form>
									<ContainersDiv>
										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
													Event Admin Role
												</Typography>
												<Typography variant="body1">
													The master role determines who can manage event (e.g. starting and ending events)
												</Typography>
											</ContainerText>
							
											<Select onChange={handleChange} value={values.roleId} name="roleId" error={Boolean(errors.roleId)} label="Event Admin Role" variant="outlined">
												{rolesSorted.map((role) => (
													<MenuItem value={role.id}>{role.name}</MenuItem>
												))}
											</Select>
											<FormHelperText>{errors.roleId || null}</FormHelperText>
											{/*<IconButton disabled={Boolean(errors.role) || isSubmitting} aria-label="save" type="submit">
										<SaveIcon />
									</IconButton>*/}
										</Container>


										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
													Enable Vote Kicking
												</Typography>
												<Typography variant="body1">
													Vote kicking allows members of an event to kick other members based on a vote.
												</Typography>
											</ContainerText>
											<Switch 
												checked={values.votekick || false}
												onChange={handleChange}
												name="votekick"
											/>
											<FormHelperText>{errors.votekick || null}</FormHelperText>
										</Container>
								

										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
													Mute Event Admins
												</Typography>
												<Typography variant="body1">
													This will prevent the bot from muting those with the server's event admin role during a karaoke event.
												</Typography>
											</ContainerText>
							
											<Switch 
												checked={values.muteEventAdmin || false}
												onChange={handleChange}
												name="muteEventAdmin"
											/>
											<FormHelperText>{errors.muteEventAdmin || null}</FormHelperText>
										</Container>
									</ContainersDiv>
									<Fade in={formModified && !isSubmitting}>
										<div>
											<Tooltip title="Save">
												<Fab 
													className={materialClasses.fabSave} 
													color="secondary" 
													type="submit" 
													disabled={Object.keys(errors).length > 0}
													onChange={handleChange}>
													<SaveIcon />
												</Fab>
											</Tooltip>
											<Tooltip title="Revert">
												<Fab size="small" className={materialClasses.fabReset} color="secondary" aria-label="reset" type="reset" onClick={() => resetForm()}>
													<UndoIcon />
												</Fab>
											</Tooltip>
										</div>
									</Fade>
								</Form>
							)}
						</Formik>
					</div>
				</Fade>
			</React.Fragment>
		);
	} else {
		return (
			<CenteredColumn>
				<CircularProgress color="secondary" />
				<Typography>
					Fetching server account
				</Typography>
			</CenteredColumn>
		);
	}
};

export {ConfigEventsPage};