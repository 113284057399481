import * as React from "react";

type MobileContextType = {
	mobile: boolean;
	setMobile?: any;
}

const MobileContext = React.createContext<MobileContextType>({
	mobile: false
});

const MobileContextProvider = MobileContext.Provider;
export {MobileContext, MobileContextProvider};