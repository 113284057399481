import { Box, Button, createStyles, Divider, List, ListItem, ListItemText, makeStyles, Paper, Step, StepLabel, Stepper, TextField, Theme, Typography, FormHelperText, ListItemIcon, Grow, Link, Select, MenuItem, CircularProgress, Card, CardMedia, CardContent } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { ContainersDiv, ContainerText, Container, CenteredRow, CenteredColumn } from "../utils/styles";
import { EDIT_GUILD_CONFIG, GET_GUILD } from "../utils/api";
import Confetti from "react-dom-confetti";
import { GuildContext } from "../utils/contexts/Guild";
import { ErrorContext } from "../utils/contexts/Error";
import { Guild, GuildConfig } from "../utils/types";
import SyncIcon from "@material-ui/icons/Sync";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import HelpIcon from "@material-ui/icons/Help";
import SettingsIcon from "@material-ui/icons/Settings";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { LoadingContext } from "../utils/contexts/Loading";
import { images, links } from "../utils/constants";
import { Helmet } from "react-helmet";
import { MobileContext } from "../utils/contexts/Mobile";

const defaultConfig = {
	prefix: ";",
	role: "Event Admin"
};

const getSteps = () => {
	return ["Intro", "Permissions", "Prefix", "Event Admin Role"];
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		stepper: {
			backgroundColor: "rgba(0,0,0,1)"
		},
		buttons: {
			margin: theme.spacing(1)
		},
		media: {
			width: "75%",
			marginLeft: "auto",
			marginRight: "auto"
		}
	})
);

const ConfigSetupPage = (props: unknown) => {
	const { mobile } = React.useContext(MobileContext);
	const { guild, setGuild } = React.useContext(GuildContext);
	const { setError } = React.useContext(ErrorContext);
	const { loading, setLoading } = React.useContext(LoadingContext);
	const [fetched, setFetched] = React.useState<boolean>(false);
	const [newConfig, setNewConfig] = React.useState<GuildConfig>(defaultConfig);
	const [disableNextButton, setDisableNextButton] = React.useState<boolean>(false);
	const [prefixInputError, setPrefixInputError] = React.useState<string | undefined>();
	const [roleInputError, setRoleInputError] = React.useState<string | undefined>();
	const [activeStep, setActiveStep] = React.useState<number>(0);
	const steps = getSteps();
	const classes = useStyles();

	// Query
	const getGuildReturn = useQuery(GET_GUILD, {
		variables: { guildId: guild?.id },
		notifyOnNetworkStatusChange: true
	});
	const dataLoading = getGuildReturn.loading || getGuildReturn.networkStatus === NetworkStatus.refetch;

	React.useEffect(() => {
		if (getGuildReturn.data && !fetched) {
			setFetched(true);
			setLoading(false);
			setGuild(getGuildReturn.data.getGuild);
		} else if (dataLoading && !fetched) {
			setLoading(true);
		}
	});

	return (
		<div>
			<Helmet>
				<title>{`Setup - ${guild?.name} | Eventcord Dashboard`}</title>
			</Helmet>
			<ContainersDiv>
				<Container mobile={mobile}>
					<Alert severity="info">
						<AlertTitle>Setup Disabled</AlertTitle>
						Setup is currently disabled via the dashboard due to it being outdated.<br />
						Please use the <strong>/SETUP</strong> command.<br />
						Sorry for the inconvenience.
					</Alert>
					<br />
					<Alert severity="info">
						<AlertTitle>Slash Commands</AlertTitle>
						Eventcord now only supports slash commands. To use slash commands type "/".
					</Alert>
				</Container>
			</ContainersDiv>
		</div>
	);
};

const ConfigSetupPageOld = (props: unknown) => {
	const { guild, setGuild } = React.useContext(GuildContext);
	const { setError } = React.useContext(ErrorContext);
	const { loading, setLoading } = React.useContext(LoadingContext);
	const [fetched, setFetched] = React.useState<boolean>(false);
	const [newConfig, setNewConfig] = React.useState<GuildConfig>(defaultConfig);
	const [disableNextButton, setDisableNextButton] = React.useState<boolean>(false);
	const [prefixInputError, setPrefixInputError] = React.useState<string | undefined>();
	const [roleInputError, setRoleInputError] = React.useState<string | undefined>();
	const [activeStep, setActiveStep] = React.useState<number>(0);
	const steps = getSteps();
	const classes = useStyles();

	// Query
	const getGuildReturn = useQuery(GET_GUILD, {
		variables: { guildId: guild?.id },
		notifyOnNetworkStatusChange: true
	});
	const dataLoading = getGuildReturn.loading || getGuildReturn.networkStatus === NetworkStatus.refetch;

	React.useEffect(() => {
		if (getGuildReturn.data && !fetched) {
			setFetched(true);
			setLoading(false);
			setGuild(getGuildReturn.data.getGuild);
		} else if (dataLoading && !fetched) {
			setLoading(true);
		}
	});

	// Mutation
	const [editConfig, editConfigReturn] = useMutation(EDIT_GUILD_CONFIG);

	React.useEffect(() => {
		// Edit guild config
		const { loading, error, data } = editConfigReturn;
		if (loading) {
			setLoading(true);
			return;
		}
		if (error) {
			setLoading(false);
			console.error(editConfigReturn.error);
			setError("Setup | Cannot update data");
			return;
		}
		if (data) {
			setLoading(false);
			console.log("Setup | Saved setup");
		}
	}, [editConfigReturn]);

	const checkPerms = () => {
		const missingPerms = [];
		const perms = Number(guild!.botPerms!);
		if ((perms & 0x0010000000) !== 0x0010000000) {
			missingPerms.push("Manage Roles");
		}
		if ((perms & 0x0004000000) !== 0x0004000000) {
			missingPerms.push("Change Nickname");
		}
		if ((perms & 0x0000000400) !== 0x0000000400) {
			missingPerms.push("View Channel");
		}
		if ((perms & 0x0000000800) !== 0x0000000800) {
			missingPerms.push("Send Messages");
		}
		if ((perms & 0x4000000000) !== 0x4000000000) {
			missingPerms.push("Send Messages In Threads");
		}
		if ((perms & 0x0000004000) !== 0x0000004000) {
			missingPerms.push("Embed Links");
		}
		if ((perms & 0x0000008000) !== 0x0000008000) {
			missingPerms.push("Attach Files");
		}
		if ((perms & 0x0000010000) !== 0x0000010000) {
			missingPerms.push("Read Message History");
		}
		if ((perms & 0x0000000040) !== 0x0000000040) {
			missingPerms.push("Add Reactions");
		}
		if ((perms & 0x0000040000) !== 0x0000040000) {
			missingPerms.push("Use External Emojis");
		}
		if ((perms & 0x2000000000) !== 0x2000000000) {
			missingPerms.push("Use External Stickers");
		}
		if ((perms & 0x0000400000) !== 0x0000400000) {
			missingPerms.push("Mute Members");
		}

		//const channelsMissingMute: {id: string, name: string}[] = [];
		const sortedChannels: { id: string, name: string, position: number/*, botPerms: string*/ }[] = [];
		guild?.channels!.forEach(channel => {
			if (channel.type !== 2) return;
			sortedChannels.push({
				id: channel.id,
				name: channel.name,
				position: channel.position,
				//botPerms: channel.botPerms
			});
		});
		sortedChannels.sort((a, b) => {
			if (a.position > b.position) return -1;
			if (a.position < b.position) return 1;
			return 0;
		});
		/*sortedChannels.forEach(channel => {
			if ((Number(channel.botPerms) & 0x400000) !== 0x400000) {
				channelsMissingMute.push({
					name: channel.name,
					id: channel.id
				});
			}
		});*/

		return { missingPerms/*, channelsMissingMute*/ };
	};

	const PermissionsChecker = () => {
		const perms = checkPerms();

		if (getGuildReturn.error) {
			<Alert severity="error">Server error</Alert>;
		}

		if (perms.missingPerms[0]) {
			// Missing Perms
			return (
				<Grow in={!dataLoading}>
					<div>
						<Alert variant="outlined" severity="warning">
							<Typography variant="subtitle1">
								Missing Server Permissions
							</Typography>
							<Typography variant="body1">
								Eventcord is missing permissions on your server which it uses to function. It is missing the following permissions.
								<br />
								<Link color="textPrimary" href="https://support.discord.com/hc/en-us/articles/206029707-How-do-I-set-up-Permissions-" target="_blank">Visit this Discord article to see how to manage permissions. Eventcord's automatically created role should be named "Kariari" in the role list.</Link>
							</Typography>
							<List>
								<Divider />
								{checkPerms().missingPerms.map(perm => (
									<React.Fragment>
										<ListItem key={perm}>
											<ListItemText
												primary={perm}
											/>
										</ListItem>
										<Divider />
									</React.Fragment>
								))}
								<Divider />
							</List>
						</Alert>
					</div>
				</Grow>
			);
		} /*else if (perms.channelsMissingMute[0]) {
			return (
				<Grow in={!dataLoading}>
					<div>
						<Alert variant="outlined" severity="warning">
							<Typography variant="subtitle1">
							Voice Channels Missing Mute Permission
							</Typography>
							<Typography variant="body1">
								Eventcord is missing the Mute Members permission in the following channels. This permission is necessary for using karaoke.
								<br />
								Since Eventcord can only unmute members which are currently connected to a voice channel, it is suggested you give Eventcord this permission in all channels so it can unmute anyone incase they leave karaoke at the wrong time.
								<br />
								Check out the video below to see how to fix this.
							</Typography>
							<List>
								<Divider />
								{checkPerms().channelsMissingMute.map(channel => (
									<React.Fragment>
										<ListItem key={channel.id}>
											<ListItemIcon>
												<VolumeUpIcon />
											</ListItemIcon>
											<ListItemText
												primary={channel.name}
												secondary={channel.id}
											/>
										</ListItem>
										<Divider />
									</React.Fragment>
								))}
								<Divider />
							</List>
							<video muted autoPlay playsInline loop className={classes.media}>
								<source src={images.permissionsVideo} type="video/webm" />
								Your browser does not support the video tag.
							</video>
						</Alert>
					</div>
				</Grow>
			);
		}*/ else {
			return (
				<Grow in={!dataLoading}>
					<Alert severity="success">No permission issues detected!</Alert>
				</Grow>
			);
		}
	};

	const prefixChanged = (event: any): void => {
		const prefix = event.target.value;
		if (prefix.length > 5) {
			setPrefixInputError("Too long! (> 5 characters)");
			setDisableNextButton(true);
		} else if (prefix.length === 0) {
			setPrefixInputError("Enter a value");
			setNewConfig({ prefix, roleId: newConfig.roleId });
			setDisableNextButton(true);
		} else if (prefix.includes("`")) {
			setPrefixInputError("Invalid character");
			setNewConfig({ prefix, roleId: newConfig.roleId });
			setDisableNextButton(true);
		} else {
			setPrefixInputError(undefined);
			setDisableNextButton(false);
			setNewConfig({ prefix, roleId: newConfig.roleId });
		}
	};

	// Roles
	const rolesSorted: { name: string, position: number, id: string }[] = [];
	guild?.roles?.forEach(role => {
		if (role.name === "@everyone") return;
		rolesSorted.push({
			name: role.name,
			position: role.position,
			id: role.id
		});
	});
	rolesSorted.sort((a, b) => {
		if (a.position > b.position) return -1;
		if (a.position < b.position) return 1;
		return 0;
	});
	const roleChanged = (event: any): void => {
		const roleId = event.target.value;
		if (!rolesSorted.find(r => r.id === roleId)) {
			setRoleInputError("Role does not exist");
			setDisableNextButton(true);
		} else {
			setNewConfig({ prefix: newConfig.prefix, roleId });
			setRoleInputError(undefined);
			setDisableNextButton(false);
		}
	};

	const getStepContent = (stepIndex: number) => {
		switch (stepIndex) {
			case 0:
				return (
					<CenteredColumn>
						<Typography variant="h6">
							👋 Hey there!
						</Typography>
						<Typography variant="body1">
							In this setup wizard, you will be guided through setting up Eventcord (aka Kariari), a Discord event management bot.
							<br />
							The default setup values will populate options within this setup wizard. To keep the default values, simply click the "next" button below.
							<strong>Changes will only be saved when you click "finish."</strong>
							<br />
							<br />
							Currently, this wizard and dashboard are only available in English and changing the bot's language is only available via the bot's ";config server locale" command.
						</Typography>
					</CenteredColumn>
				);
			case 1:
				return (
					<CenteredColumn>
						<ContainerText>
							<Typography variant="h6">
								Permissions Check
							</Typography>

							<Button disabled={dataLoading} className={classes.buttons} size="small" variant="contained" startIcon={<SyncIcon />} onClick={() => { getGuildReturn.refetch(); setFetched(false); }}>
								Recheck Permissions
							</Button>

							<PermissionsChecker />

							{/* temp alert */}
							<br />
							<Alert variant="outlined" severity="info">
								<Typography variant="subtitle1">
									Voice Channels Missing Mute Permission
								</Typography>
								<Typography variant="body1">
									Eventcord is currently unable to verify mute permissions in channels via the dashboard. Please use "/setup" to check this.
									<br />
									Since Eventcord can only unmute members which are currently connected to a voice channel, it is suggested you give Eventcord this permission in all channels so it can unmute anyone incase they leave karaoke at the wrong time.
									<br />
									<Link href={images.permissionsVideo} target="_blank" color="textSecondary">Video: how to change permissions</Link>
								</Typography>
							</Alert>

							<Typography variant="body1">
								This bot requires many permissions to operate optimally. Please correct any issues seen above. If none exist, feel free to move on to the next step.
							</Typography>
						</ContainerText>
					</CenteredColumn>
				);
			case 2:
				if (prefixInputError && !disableNextButton) {
					setDisableNextButton(true);
				}
				return (
					<CenteredColumn>
						<Typography variant="h6">
							Set a Prefix
						</Typography>
						<Typography variant="body1">
							Eventcord allows for you to change the prefix which the bot uses on your server. This is useful if you have many bots with the same default prefix.
							<br />
							Eventcord's default prefix is ";"
						</Typography>

						<TextField
							onChange={prefixChanged}
							value={newConfig.prefix}
							label="Prefix"
							variant="outlined"
							error={Boolean(prefixInputError)}
							helperText={prefixInputError}
						/>
					</CenteredColumn>
				);
			case 3:
				if (roleInputError && !disableNextButton) {
					setDisableNextButton(true);
				}
				return (
					<CenteredColumn>
						<Typography variant="h6">
							Set the Event Admin Role
						</Typography>
						<Typography variant="body1">
							Eventcord uses a role to determine who can manage events (e.g. start and stop events).
							<br />
							Eventcord's default event admin role is "Event Admin"
							<br />
							Check out the video below to see how to create a role, in the video's case, the default role name.
							<br />
							<Link color="textSecondary" target="_blank" href={links.eventAdminRoleInfo}>More information of the event admin role</Link>
						</Typography>
						<Select onChange={roleChanged} value={newConfig.roleId} name="role" error={Boolean(roleInputError)} label="Event Admin Role" variant="outlined">
							{rolesSorted.map((role) => (
								<MenuItem value={role.id}>{role.name}</MenuItem>
							))}
						</Select>
						<FormHelperText>{roleInputError || null}</FormHelperText>
						<video muted autoPlay playsInline loop className={classes.media}>
							<source src={images.createRoleVideo} type="video/webm" />
							Your browser does not support the video tag.
						</video>
					</CenteredColumn>
				);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		setDisableNextButton(false);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setDisableNextButton(false);

		if (activeStep === steps.length - 1) {
			if (guild) {
				editConfig({ variables: { input: newConfig, guildId: guild.id } });
			}
		}
	};

	// Finish data
	const Finished = () => {
		const { loading, error, data } = editConfigReturn;
		if (loading) {
			return (
				<CenteredColumn>
					<CircularProgress color="secondary" />
				</CenteredColumn>
			);
		}
		if (error) {
			return (
				<CenteredColumn>
					<Alert severity="error">Server error</Alert>
				</CenteredColumn>
			);
		}
		return (
			<CenteredColumn>
				<Alert severity="success">Saved!</Alert>
				<Typography variant="body1">
					Your settings have been saved! Open up Discord to get started using the bot!
				</Typography>
				<Link target="_blank" href={links.docs}>
					<Button
						className={classes.buttons}
						variant="outlined"
						startIcon={<MenuBookIcon />}
					>
						Docs
					</Button>
				</Link>
				<Link target="_blank" href={links.supportServer}>
					<Button
						className={classes.buttons}
						variant="outlined"
						startIcon={<HelpIcon />}
					>
						Support Server
					</Button>
				</Link>
			</CenteredColumn>
		);

	};

	return (
		<div>
			<Helmet>
				<title>{`Setup - ${guild?.name} | Eventcord Dashboard`}</title>
			</Helmet>
			<Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<div>
				{activeStep === steps.length ? (
					<div>
						<Finished />
					</div>
				) : (
					<React.Fragment>
						{getStepContent(activeStep)}
						<CenteredRow>
							<div>
								<Button
									className={classes.buttons}
									disabled={activeStep === 0}
									onClick={handleReset}
								>
									Reset
								</Button>
								<Button
									className={classes.buttons}
									disabled={activeStep === 0}
									onClick={handleBack}
								>
									Back
								</Button>
								<Button
									className={classes.buttons}
									variant="contained"
									onClick={handleNext}
									disabled={disableNextButton}
								>
									{activeStep === steps.length - 1 ? "Finish" : "Next"}
								</Button>
							</div>
						</CenteredRow>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export { ConfigSetupPage };