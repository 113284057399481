import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter as Router} from "react-router-dom";
import { DarkTheme, MaterialTheme } from "./utils/themes";
import { ThemeProvider } from "styled-components";
import { IconButton, MuiThemeProvider } from "@material-ui/core";
import {SnackbarKey, SnackbarProvider} from "notistack";
import { Ref } from "react";
import CloseIcon from "@material-ui/icons/Close";

const notistackRef: Ref<SnackbarProvider> = React.createRef();
const snackbarOnClickDismiss = (key: SnackbarKey) => () => {
	notistackRef.current!.closeSnackbar(key);
};

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<ThemeProvider theme={DarkTheme}>
				<MuiThemeProvider theme={MaterialTheme}>
					<SnackbarProvider
						ref={notistackRef}
						action={(key) => (
							<IconButton onClick={snackbarOnClickDismiss(key)}>
								<CloseIcon />
							</IconButton>
						)}
					>
						<App />
					</SnackbarProvider>
				</MuiThemeProvider>
			</ThemeProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
