import { createMuiTheme } from "@material-ui/core/styles";
import { dark } from "@material-ui/core/styles/createPalette";

type Theme = {
	base?: any;
	text?: any;
	button?: any;
	height?: any;
	padding?: any;
}

export const DarkTheme: Theme = {
	base: {
		TopNav: "#363636",
		content: "#000000",
		container: "#484848"
	},
	text: {
		main: "#FFFFFF"
	},
	button: {
		main: "#BB86FC"
	},
	height: {
		top: "60px"
	},
	padding: {
		content: "15px 10px"
	}
};

export const MaterialTheme = createMuiTheme({
	palette: {
		type: "dark",
		primary: {
			main: "#212121"
		},
		secondary: {
			main: "#BB86FC"
		}
	},
	typography: {
		fontFamily: "Cabin"
	},
	props: {
		MuiMenu: {
			disableScrollLock: true
		},
		MuiDrawer: {
			ModalProps: {
				disableScrollLock: true
			}
		},
		MuiDialog: {
			disableScrollLock: true
		}
	}
});