import { Event, EventProps } from "../utils/types";
import React from "react";
import { LoadingContext } from "../utils/contexts/Loading";
import { GET_EVENTS, EDIT_EVENT } from "../utils/api";
import { useMutation, useQuery } from "@apollo/client";
import { GuildContext } from "../utils/contexts/Guild";
import { ErrorContext } from "../utils/contexts/Error";
import { BasePageHeader, CenteredColumn, Container, ContainersDiv, ContainerText } from "../utils/styles";
import { Button, CircularProgress, createStyles, Divider, Fab, Fade, FormHelperText, Grow, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Paper, Slider, Switch, TextField, Theme, Tooltip, Typography } from "@material-ui/core";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import EditIcon from "@material-ui/icons/Edit";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";
import { MobileContext } from "../utils/contexts/Mobile";

const afterSubmitTimeout = 1000;

// Fab styles
const useMaterialStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			width: 500,
			position: "relative",
			minHeight: 200,
		},
		fabSave: {
			position: "fixed",
			bottom: theme.spacing(4),
			right: theme.spacing(4),
		},
		fabReset: {
			position: "fixed",
			bottom: theme.spacing(14),
			right: theme.spacing(5),
		},
		hidden: {
			visibility: "hidden"
		}
		/*fabGreen: {
			color: theme.palette.common.white,
			backgroundColor: green[500],
			"&:hover": {
				backgroundColor: green[600],
			},
		},*/
	}),
);

const ManageEventsPage = (props: EventProps) => {
	const materialClasses = useMaterialStyles();
	const history = props.history;
	const {channelId} = props.match.params;
	const {setLoading} = React.useContext(LoadingContext);
	const {setError} = React.useContext(ErrorContext);
	const {guild} = React.useContext(GuildContext);
	const {mobile} = React.useContext(MobileContext);
	const [events, setEvents] = React.useState<Event[]>();
	const [event, setEvent] = React.useState<Event>();
	const [formModified, setFormModified] = React.useState<boolean>(false);

	const getEventsReturn = useQuery(GET_EVENTS, {variables: {guildId: guild!.id}});
	const [editEvent, editEventReturn] = useMutation(EDIT_EVENT);

	React.useEffect(() => {
		console.log("Fetching events");
		if (!events) {
			const {loading, error, data} = getEventsReturn;
			if (loading) {
				setLoading(true);
				return;
			}
			if (error) {
				setError("Cannot fetch events");
				return;
			}
			if (data) {
				setEvents(data.getEvents);
				setLoading(false);
				return;
			}
		}
	}, [getEventsReturn]);

	React.useEffect(() => {
		// load and save new event config
		const {loading, error, data} = editEventReturn;
		if (loading) {
			setLoading(true);
			return;
		}
		if (error) {
			setLoading(false);
			console.error(editEventReturn.error);
			setError("Cannot update data");
			return;
		}
		if (data) {
			setLoading(false);
			setEvent(data.editEvent);
			console.log("updated event");
			return;
		}
	}, [editEventReturn]);

	const findChannel = (id: string) => {
		if (guild) {
			if (guild.channels) {
				const find = guild.channels.find(c => c.id === id);
				if (find) {
					return find;
				}
				return undefined;
			}
			return undefined;
		}
		return undefined;
	};

	const getEventsListIcon = (type: number) => {
		switch (type) {
		case (1):
			return "🎉";
			/*return (
				<MusicNoteIcon />
			);*/
		case (2):
			return "🎤";
			/*return (
				<PeopleAltIcon />
			);*/
		}
		return null;
	};

	if (!event) {
		const find = events?.find(event => event.textChannel === channelId);
		if (find) setEvent(find);
	}

	if (event) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{`#${findChannel(event.textChannel)?.name} - ${guild?.name} | Eventcord Dashboard`}</title>
				</Helmet>
				<Fade in timeout={500}>
					<div>
						<BasePageHeader>
							<Typography variant="h4">
								{`${getEventsListIcon(event.type!)} #${findChannel(event!.textChannel)?.name}`}
							</Typography>
						</BasePageHeader>
						<Formik
							enableReinitialize
							initialValues={{
								message: event.message,
								locked: event.locked,
								autoCycle: event.autoCycle,
								oneRound: event.oneRound,
								maxGroupSize: event.maxGroupSize
							}}
							onReset={() => {
								setFormModified(false);
							}}
							onSubmit={({message, locked, autoCycle, oneRound, maxGroupSize}, {setSubmitting}) => {
								setFormModified(false);
								editEvent({variables: {input: {message, locked, autoCycle, oneRound, maxGroupSize}, channelId, guildId: guild?.id}});
								setTimeout(() => {
									setSubmitting(false);
								}, afterSubmitTimeout);
							}}
							validate={(values) => {
								const errors = {} as {message: string, autoCycle: string, maxGroupSize: string, unchanged: boolean};

								let changed = false;
								Object.keys(values).forEach(key => {
									//@ts-ignore
									if (values[key] !== event[key]) {
										changed = true;
									}
								});
								if (changed) {
									setFormModified(true);
								} else {
									setFormModified(false);
									errors.unchanged = true;
								}

								// message check
								const message = values.message || "";
								if (message.length > 280) {
									errors.message = "Too long! (> 280 characters)";
								} else if (message.length === 0) {
									errors.message = "Please enter a value";
								}

								// Autocycle check
								let autoCycle = values.autoCycle;
								if (autoCycle !== null) {
									autoCycle = Number(autoCycle);
									if (autoCycle < 30 || autoCycle > 32767) {
										errors.autoCycle = "Invalid value (min 30 seconds, max 32767 seconds)";
									}
								}

								// maxGroupSize check
								const maxGroupSize = Number(values.maxGroupSize);
								if (maxGroupSize < 1 || maxGroupSize > 4) {
									errors.maxGroupSize = "Invalid value (min 1, max 4)";
								}

								return errors;
							}}
						>
							{({errors, handleChange, initialValues, isSubmitting, resetForm, values, setFieldValue}) => (
								<Form>
									<ContainersDiv>
										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
												Message
												</Typography>
												<Typography variant="body1">
												Change the event's message
												</Typography>
											</ContainerText>
											<TextField 
												variant="filled" 
												error={Boolean(errors.message)} 
												value={values.message} 
												helperText={`${values.message!.length}/280`} 
												label="Message" 
												name="message" 
												onChange={handleChange}
												multiline
											/>
										</Container>

										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
												Lock Event
												</Typography>
												<Typography variant="body1">
												Lock the event to prevent anyone new from joining.
												</Typography>
											</ContainerText>
											<Switch 
												checked={values.locked || false}
												onChange={handleChange}
												name="locked"
											/>
										</Container>

										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
												Remove After Cycle
												</Typography>
												<Typography variant="body1">
												Whether the group in the 0th position should be removed when the event is cycled.
												</Typography>
											</ContainerText>
											<Switch 
												checked={values.oneRound || false}
												onChange={handleChange}
												name="oneRound"
											/>
										</Container>
								
										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
												Auto Cycle
												</Typography>
												<Typography variant="body1">
												Have to bot automatically cycle based on time in seconds
												</Typography>
											</ContainerText>
											<Switch 
												checked={values.autoCycle === null ? false : true}
												onChange={(event) => {
													const value = event.target.checked;
													if (value === true) {
														setFieldValue("autoCycle", 30);
													} else {
														setFieldValue("autoCycle", null);
													}
												}}
												name="autoCycleSwitch"
											/>
											<Slider
												value={values.autoCycle || 30}
												valueLabelDisplay="auto"
												step={10}
												min={30}
												max={300}
												marks
												name="autoCycle"
												onChange={(event, value) => {
													setFieldValue("autoCycle", value);
												}}
												disabled={values.autoCycle === null ? true : false}
											/>
											<TextField
												value={values.autoCycle || 30}
												onChange={handleChange}
												disabled={values.autoCycle === null ? true : false}
												name="autoCycle"
												label="Seconds"
												type="number"
												inputProps={{
													min: 30,
													max: 32767,
													step: 10
												}}
												error={Boolean(errors.autoCycle)}
												helperText={errors.autoCycle || null}
											/>
										</Container>

										<Container mobile={mobile}>
											<ContainerText>
												<Typography variant="h6">
													Group Size
												</Typography>
												<Typography variant="body1">
													Set the Max Group Size
												</Typography>
											</ContainerText>
											<Slider
												value={Number(values.maxGroupSize)}
												valueLabelDisplay="auto"
												step={1}
												min={1}
												max={4}
												marks
												onChange={(event, value) => {
													setFieldValue("maxGroupSize", Number(value));
												}}
												name="maxGroupSize"
											/>
											<FormHelperText>{errors.maxGroupSize}</FormHelperText>
										</Container>
									</ContainersDiv>
									<Fade in={formModified && !isSubmitting}>
										<div>
											<Tooltip title="Save">
												<Fab 
													className={materialClasses.fabSave} 
													color="secondary" 
													type="submit" 
													disabled={Object.keys(errors).length > 0}
													onChange={handleChange}>
													<SaveIcon />
												</Fab>
											</Tooltip>
											<Tooltip title="Revert">
												<Fab size="small" className={materialClasses.fabReset} color="secondary" aria-label="reset" type="reset" onClick={() => resetForm()}>
													<UndoIcon />
												</Fab>
											</Tooltip>
										</div>
									</Fade>
								</Form>
							)}
						</Formik>
					</div>
				</Fade>
			</React.Fragment>
		);
	}

	if (events) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{`Events - ${guild?.name} | Eventcord Dashboard`}</title>
				</Helmet>
				<Fade in>
					<CenteredColumn>
						<Container mobile={mobile}>
							<Typography variant="h4">
								Select an event
							</Typography>
							<Typography variant="body1">
								{events[0] ? "Select which event you would like to manage" : "No active events. Use \";start\" within Discord to begin one."}
							</Typography>
							<List>
								<Divider key="topDivider" />
								{events.map((event, i) => (
									<React.Fragment>
										<ListItem key={event.textChannel}>
											<Grow timeout={i + 1 * 1000} in>
												<div>
													<ListItemText
														primary={`#${findChannel(event.textChannel)?.name} ${getEventsListIcon(event.type!)}`}
														primaryTypographyProps={{
															variant: "h6"
														}}
														secondary={
															<React.Fragment>
																<Typography color="textPrimary" variant="body1">
																	{`Message: ${event.message}`}
																	<br />
																	{`Locked: ${event.locked ? "🔒 (locked)" : "🔓 (unlocked)"}`}
																	<br />
																	{`Auto Cycle: ${event.autoCycle} seconds`}
																	<br />
																	{`Remove After Cycle: ${event.oneRound ? "✔️" : "❌"}`}
																</Typography>
																<Button
																	variant="contained"
																	endIcon={<EditIcon />}
																	onClick={() => history.push(`/dash/${guild?.id}/manage/events/${event.textChannel}`)}
																>
																Manage
																</Button>
															</React.Fragment>
														}
													/>
												</div>
											</Grow>
										</ListItem>
										<Divider key={`${event.textChannel}Divider`} />
									</React.Fragment>
								))}
							</List>
						</Container>
					</CenteredColumn>
				</Fade>
			</React.Fragment>
		);
	}
	
	return (
		<CenteredColumn>
			<CircularProgress color="secondary" />
			<Typography>
				Fetching events
			</Typography>
		</CenteredColumn>
	);
};

export {ManageEventsPage};