import * as React from "react";
import * as constants from "../utils/constants";
import {  List, ListItem, Drawer, ListItemIcon, ListItemText, Divider, Link, Button, Avatar, Menu, MenuItem, ListSubheader } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import * as events from "events";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BuildIcon from "@material-ui/icons/Build";
import TranslateIcon from "@material-ui/icons/Translate";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import GroupIcon from "@material-ui/icons/Group";
import HelpIcon from "@material-ui/icons/Help";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import DnsIcon from "@material-ui/icons/Dns";
import {History} from "history";
import { GuildContext } from "../utils/contexts/Guild";
import { Guild, MenuProps } from "../utils/types";
import { GuildsContext } from "../utils/contexts/Guilds";
import { UserContext } from "../utils/contexts/User";
import { CenteredColumn } from "../utils/styles";
import { generatePath } from "react-router-dom";
import { MobileContext } from "../utils/contexts/Mobile";
import { InviteDialogContext } from "../utils/contexts/InviteDialog";

const useStyles = makeStyles((theme: Theme) => 
	createStyles({
		list: {
			width: 250,
		},
		avatar: {
			marginRight: theme.spacing(1)
		},
		button: {
			margin: theme.spacing(0.5),
			maxWidth: "100%"
		}
	})
);

export const SideMenu = (props: MenuProps): any => {
	const classes = useStyles();
	const {guild} = React.useContext(GuildContext);
	const {guilds} = React.useContext(GuildsContext);
	const {user} = React.useContext(UserContext);
	const {mobile} = React.useContext(MobileContext);
	const {setInviteDialogOpen} = React.useContext(InviteDialogContext);

	/* Selection menus if mobile */
	// User Menu
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseUser = () => {
		setAnchorElUser(null);
	};
	const handleLogout = () => {
		handleCloseUser();
		window.location.href = process.env.REACT_APP_LOGOUT_URL || "/";
	};
	// Server Menu
	const [anchorElServer, setAnchorElServer] = React.useState<null | HTMLElement>(null);
	const handleClickServer = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElServer(event.currentTarget);
	};
	const handleCloseServer = () => {
		setAnchorElServer(null);
	};
	// Avatars
	const getGuildIconUrl = (guild?: Guild): string => {
		if (!guild) return "";
		if (guild.icon) {
			return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
		} else {
			return "";
		}
	};
	const getUserAvatarUrl = (): string => {
		if (user) {
			if (user.avatar) {
				if (user.avatar.startsWith("a_")) {
					return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.gif?size=64`;
				}
				return `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png?size=64`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${Number(user.discordTag.split("#")[1]) % 5}.png?size=64`;
			}
		} else {
			return "";
		}
	};

	const state = props.menuOpen;
	
	const toggleMenu = () => {
		props.setMenuOpen(!state);
	};

	function optionsList() {
		if (guild) {
			return constants.menuItems(guild.id).map((item) => {
				return (
					<List
						subheader={
							<ListSubheader>
								{item.name.toUpperCase()}
							</ListSubheader>
						}
					>
						{item.routes.map((route) => (
							<ListItem button key={route.name} onClick={() => {props.history.push(route.path); toggleMenu();}}>
								<ListItemText primary={route.name} />
							</ListItem>
						))}
					</List>
				);
			});
		}
	}

	return (
		<React.Fragment>
			<Drawer anchor={"left"} onClose={toggleMenu} open={state}>
				<div className={classes.list}>
					<List>
						<ListItem button key="close" onClick={toggleMenu}>
							<ListItemIcon><ArrowBackIcon/></ListItemIcon> 
							<ListItemText primary='Close' /> 
						</ListItem>
					</List>

					<Divider />

					{optionsList()}

					<Divider />

					{mobile ? (
						<React.Fragment>
							<CenteredColumn>
								<Button className={classes.button} variant="outlined" color="secondary" onClick={handleClickServer}>
									<Avatar className={classes.avatar} src={getGuildIconUrl(guild)} ></Avatar>
									{guild?.name || "Manage a server"}
								</Button>

								<Menu id="user" anchorEl={anchorElServer} keepMounted open={Boolean(anchorElServer)} onClose={handleCloseServer}>
									{/* Server Selector */}
									{guilds?.map((guild) => (
										<MenuItem key={guild.id} onClick={() => {
											handleCloseServer();
											props.history.push("/dash/" + guild.id + "/");
											//props.history.push(generatePath(props.match.path, {guildId: guild.id, route: props.match.params.route}));
											//window.location.reload();
										}}>
											<Avatar className={classes.avatar} src={getGuildIconUrl(guild)} />
											{guild.name}
										</MenuItem>
									))}

									<MenuItem 
										key="invite"
										onClick={() => {
											setInviteDialogOpen(true);
											handleCloseServer();
										}}
									>
										<Avatar className={classes.avatar}>
											<PersonAddIcon />
										</Avatar>
										Invite Bot
									</MenuItem>
								
								</Menu>

								<Button className={classes.button} variant="outlined" color="secondary" onClick={handleClickUser}>
									<Avatar className={classes.avatar} src={getUserAvatarUrl()}></Avatar>
									{user?.discordTag || "User#0001"}
								</Button>

								<Menu id="user" anchorEl={anchorElUser} keepMounted open={Boolean(anchorElUser)} onClose={handleCloseUser}>
									<MenuItem key="logout" onClick={handleLogout}>Logout</MenuItem>
								</Menu>
							</CenteredColumn>
							<Divider />
						</React.Fragment>
					) : null}

					<List>
						<Link color="textPrimary" href={constants.links.mainSite} target="_blank">
							<ListItem button key="home">
								<ListItemIcon><HomeIcon/></ListItemIcon> 
								<ListItemText primary='Home' /> 
							</ListItem>
						</Link>
						<Link color="textPrimary" href={constants.links.docs} target="_blank">
							<ListItem button key="docs">
								<ListItemIcon><MenuBookIcon/></ListItemIcon> 
								<ListItemText primary='Docs' /> 
							</ListItem>
						</Link>
						<Link color="textPrimary" href={constants.links.supportServer} target="_blank">
							<ListItem button key="supportServer">
								<ListItemIcon><HelpIcon/></ListItemIcon> 
								<ListItemText primary='Support Server' /> 
							</ListItem>
						</Link>
						<Link color="textPrimary" href={constants.links.statusPage} target="_blank">
							<ListItem button key="status">
								<ListItemIcon><DnsIcon/></ListItemIcon> 
								<ListItemText primary='Status' /> 
							</ListItem>
						</Link>
						<Link color="textPrimary" onClick={() => {
							props.history.push("/contribute/");
							toggleMenu();
						}}>
							<ListItem button key="contribute">
								<ListItemIcon><TranslateIcon/></ListItemIcon> 
								<ListItemText primary='Translate' /> 
							</ListItem>
						</Link>
					</List>
				</div>
			
			</Drawer>
		</React.Fragment>
	);
};
