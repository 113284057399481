import React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import axios from "axios";
import { Formik, Form, FormikProps, useFormik, useFormikContext } from "formik";
import { BasePageHeader, CenteredColumn, Container, ContainersDiv } from "../utils/styles";
import { LoadingContext } from "../utils/contexts/Loading";
import { Helmet } from "react-helmet";
import { Button, CircularProgress, createStyles, makeStyles, Snackbar, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ErrorContext } from "../utils/contexts/Error";
import { UserContext } from "../utils/contexts/User";

type Props = RouteComponentProps<{id: string}>

const BotErrorPage = (props: Props) => {
	const [submitted, setSubmitted] = React.useState({error: "", success: false});
	const {loading} = React.useContext(LoadingContext);
	const {setError} = React.useContext(ErrorContext);
	
	const useQuery = () => {
		return new URLSearchParams(props.location.search);
	};
	const query = useQuery();
	const errorId = query.get("id");

	if (loading === true) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{"Bot Error | Eventcord Dashboard"}</title>
				</Helmet>
				<CenteredColumn>
					<CircularProgress color="secondary" />
					<Typography>
					Fetching user account
					</Typography>
				</CenteredColumn>
			</React.Fragment>
		);
	}

	if (submitted.success) {
		return (
			<React.Fragment>
				<Helmet>
					<title>{"Bot Error | Eventcord Dashboard"}</title>
				</Helmet>
				<CenteredColumn>
					<Alert severity="success">Thank you for the feedback!</Alert>
				</CenteredColumn>
			</React.Fragment>
		);
	}

	if (submitted.error !== "") {
		return (
			<React.Fragment>
				<Helmet>
					<title>{"Bot Error | Eventcord Dashboard"}</title>
				</Helmet>
				<CenteredColumn>
					<Alert severity="error">{submitted.error}</Alert>
				</CenteredColumn>
			</React.Fragment>
		);
	}

	if (errorId === null || errorId === "") {
		return (
			<React.Fragment>
				<Helmet>
					<title>{"Bot Error | Eventcord Dashboard"}</title>
				</Helmet>
				<CenteredColumn>
					<Alert severity="error">No error ID. You may be on this page in error.</Alert>
				</CenteredColumn>
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			<Helmet>
				<title>{"Bot Error | Eventcord Dashboard"}</title>
			</Helmet>
			<Formik
				initialValues={{feedback: ""}}
				onSubmit={({feedback}, {setSubmitting}) => {
					setSubmitting(true);
					axios.post(process.env.REACT_APP_API + "/user-error-feedback", {
						feedback,
						errorId
					}, {
						withCredentials: true
					})
						.then(() => {
							setSubmitting(false);
							setSubmitted({success: true, error: ""});
						})
						.catch(e => {
							setSubmitting(false);
							console.error(e);
							if (e.response.status === 409) {
								setSubmitted({success: false, error: "Cannot submit feedback for this error"});
								return;
							}
							setError("Error submitting feedback");
						});
				}}
				validate={({feedback}) => {
					if (feedback === "") {
						return {feedback: true};
					}
					if (feedback.length > 560) {
						return {feedback: true};
					}
					return {};
				}}
			>
				{({handleChange, isSubmitting, values, errors}) => (
					<Form>
						<ContainersDiv>
							<Container mobile={true}>
								<Typography variant="h6">
								Error Feedback
								</Typography>
								<Typography variant="body1">
									Provide feedback for an error
								</Typography>
								<TextField
									variant="filled"
									label="Feedback"
									value={values.feedback}
									name="feedback"
									onChange={handleChange}
									multiline
									helperText={`${values.feedback.length}/560`}
									error={Boolean(errors.feedback)}
								/>
								<Button
									variant="contained"
									color="secondary" 
									type="submit"
									disabled={isSubmitting || Boolean(errors.feedback) || submitted.success}
								>
								Submit
								</Button>
							</Container>
						</ContainersDiv>
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};

export {BotErrorPage};