import * as constants from "../utils/constants";
import * as React from "react";
import { Avatar, makeStyles, Link } from "@material-ui/core";
import { HomePageButtonStyle, HomePageButtonContainerStyle, HomePageHeaderStyle, HomePageStyle, HomePageTextStyle, FooterStype } from "../utils/styles";

const useStyles = makeStyles({
	text: {
		color: "#fff"
	}
});

const HomePage = (props: any) => {
	const classes = useStyles();
	const loginUrl = process.env.REACT_APP_LOGIN_URL;
	return (
		<React.Fragment>
			<HomePageStyle backgroundImageUrl={`${process.env.PUBLIC_URL}/images/homeBackground.png`}>
				<HomePageHeaderStyle>Eventcord Dashboard</HomePageHeaderStyle>
				<HomePageTextStyle>
					Please sign in with Discord to continue
					<br />
					Currently, only English is available for the dashboard
				</HomePageTextStyle>
				<HomePageButtonContainerStyle>
					<a href={loginUrl}><HomePageButtonStyle>Open Dashboard</HomePageButtonStyle></a>
				</HomePageButtonContainerStyle>
			</HomePageStyle>
			<FooterStype>
				<Link href={constants.links.mainSite} className={classes.text} target="_blank">
					Eventcord
				</Link>
			</FooterStype>
		</React.Fragment>
	);
};

export {HomePage};