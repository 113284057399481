import * as React from "react";
import { User } from "../types";

type UserContextType = {
	user?: User;
	setUser?: any;
}

const UserContext = React.createContext<UserContextType>({
	user: undefined,
});

const UserContextProvider = UserContext.Provider;
export {UserContext, UserContextProvider};